const assignCheckHorarioVideo = (params,message = 'El horario de reserva es de 11 a 20Hs') => {
  const checkHorarioVideo = (rule, value, callback) => {
   
    if( value && (value.hour() < 11 || ( value.hour() == 20 && value.minute() > 0) || value.hour() > 20))
    {
      callback(message);
    } else
    { 
      callback();
    }
    
   
  };
  return checkHorarioVideo;
};

export default assignCheckHorarioVideo;