import React from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm, Input as AntInput } from 'antd';
import mergeCustomRules from '../customRules';

const AntFormItem = AntForm.Item;
const { TextArea } = AntInput;

class TextAreaItem extends React.Component {
 
  render() {
    const { getFieldDecorator } = this.props.containerForm;
    let inputProps = Object.assign({},this.props);
    
    delete inputProps.isFormEdition;
    delete inputProps.containerForm;
    delete inputProps.rules;
    delete inputProps.validateTrigger;
    delete inputProps.label;
    delete inputProps.name;
    delete inputProps.help;
    delete inputProps.hasFeedback;
    delete inputProps.formItemLayout;
    
    return (
      <AntFormItem
        style={ { marginBottom: 2 } }
        label= { this.props.label }
        help = { this.props.help }
        { ...this.props.formItemLayout }
      >
        {
          getFieldDecorator( this.props.name, 
            {
              validateTrigger: this.props.validateTrigger,
              rules: mergeCustomRules(this.props.rules),
            })(
            ( <TextArea rows={ 4 } { ...inputProps }/> )
          )
        }
      </AntFormItem>
    );
  }
}

TextAreaItem.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formItemLayout: PropTypes.object,
  containerForm: PropTypes.object.isRequired
};

const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

TextAreaItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  placeholder: ' ',
  formItemLayout: defaultFormItemLayout
};

export default TextAreaItem;