import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
//import FormItem from 'antd/lib/form/FormItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import secretarios from '../../secretarios';

//const Option = Select.Option;


const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteCorreoElectronicoStep extends React.Component {

  state = {
    accion: null
  }

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let tituloPeticion = '';
    let objsec= { nombre:'',secretaria:''};
    let objsecparent= { nombre:'',secretaria:'',email:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    let indice = secretarios.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsec = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,parent: secretarios[indice].parent  };

    if(objsec.parent){
      let indice = secretarios.findIndex(elem => elem.id == objsec.parent);
      if(indice > -1)
        objsecparent = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,email: secretarios[indice].email };
    }
  
    console.log(objsec);
    console.log(objsecparent);

    if (this.state.accion === 'ALTA_PERSONAL') {
      tituloPeticion = 'Creación de Cuenta de Correo Electrónico';


      descripcionPeticion += `\\n   -------- Datos del Responsable del Trámite --------`;
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
        descripcionPeticion += `\\n  Nombre Autoridad: ` + objsecparent.nombre; 
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n  --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n  Nombre del Agente: ${formData.nombreCuenta}`;
      descripcionPeticion += `\\n   Apellido del Agente: ${formData.apellidoCuenta}`;
      descripcionPeticion += `\\n  Legajo del Agente: ${formData.legajo || '-'}`;
    }

    if (this.state.accion === 'ALTA_SECTOR') {
      tituloPeticion = 'Creación de Cuenta de Correo Electrónico';
      descripcionPeticion += `\\n   -------- Datos del Responsable del Trámite --------`;
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n  --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n  Nombre del Sector: ${formData.nombreSector}`;
    
    }

    if (this.state.accion === 'MODIFICACION') {
      tituloPeticion = 'Modificación de Cuenta de Correo Electrónico';
      descripcionPeticion += `\\n -------- Datos del Responsable del Trámite --------`;
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n Tipo de Modificación: ${formData.tipoModificacion}`;
      descripcionPeticion += `\\n Correo del Agente: ${formData.correo}`;
      
    

    }

    if (this.state.accion === 'SUSPENSION') {
      tituloPeticion = 'Suspensión de Cuenta de Correo Electrónico';
      descripcionPeticion += `\\n -------- Datos del Responsable del Trámite --------`;
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n Correo del Agente: ${ formData.correo}`;
      
    
    }


    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;

    if(formData.motivo)
      descripcionPeticion += ` \\n  Motivo: ${formData.motivo || '-'}`;

    const tramiteTipo = 'GESTION_CORREO_ELECTRONICO';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 10935,
        "subject": "${ tituloPeticion }",
        "description": "${ descripcionPeticion }",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": 336,
        "priority_id": 2,
        "watcher_user_ids":[478,328],
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
      }
    }`;

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    if(objsecparent.email)
      objetoenviar.emailparent = objsecparent.email;
    
    this.props.actions.saveTramite(
      objetoenviar,
      () => { this.props.actions.nextStep(); }
    ); 
    
  }

  getNombreInput = accion => (
    <InputItem
      label="Nombre del Agente"
      name="nombreCuenta"
      rules={ (accion == 'ALTA_PERSONAL')?
        [{
          required: true,
          message: 'El Nombre del Agente es Obligatorio'
        }]
        : []
      }
    />
  )
  
  getApellidoInput = accion => (
    <InputItem
      label="Apellido del Agente"
      name="apellidoCuenta"
      rules={ (accion == 'ALTA_PERSONAL')?
        [{
          required: true,
          message: 'El Nombre del Agente es Obligatorio'
        }]
        : []
      }
    />
  )

  
  
  getLegajoInput = () => (
    <InputItem
      label="Legajo del Agente"
      name="legajo"
    />
  )

  getCorreoInput = () => (
    <InputItem
      label="Cuenta de correo institucional"
      name="correo"
      rules={ 
        [{
          required: true,
          message: 'El email institucional es obligatorio'
        },
        {
          type: 'email',
          message: 'Debe ingresar un email valido',
        },
        {
          type: 'CHECK_RECTORADO_EMAIL'
        }] 
      }
    />
  )

  getTipoModifSelect = () => (
    <SelectItem
      label="Tipo de modificación"
      name="tipoModificacion"
      fieldsValues={ ['value'] }
      fieldsToShow={ ['label'] }
      staticData={ [
        { label: 'Recupero de contraseña', value: 'RECUPERO_DE_CONTRASEÑA' },
        { label: 'Otros', value: 'OTRO' },

      ] }
      rules={ [{ required: true, message: 'Este campo es requerido' }] }
    />
  )

  getMotivoInput = () => (
    <TextAreaItem
      label="Motivo"
      name="motivo"
      rules={ [{ required: false }] }
    />
  )

  getCuentaSectorInput = () => (
    <InputItem
      label="Cuenta de correo institucional sugerida"
      name="CuentaSugerida"
      help="Ingresar la cuenta. Ejemplo: sec-tic@utn.edu.ar"
      rules={ [{ required: true, message: 'La cuenta es requerida' }] }
    />
  )

  getNombreSectorInput = () => (
    <InputItem
      label="Nombre del sector"
      name="nombreSector"
      help="Ingresar el nombre del sector. Ejemplo: Secretaría TIC "
      rules={ [{ required: true, message: 'El nombre es requerido' }] }
    />
  )

  getComentarioInput = () => (
    <TextAreaItem
      label="Comentario"
      name="comentario"
      rules={ [{ required: false }] }
    />
  )

  render() {
    const { accion } = this.state;
    /*
    const defaultFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    */

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form 
            onSave={ this.handleSave }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
           

            <SelectItem
              label="Tipo de Solicitud"
              name="tipoAccion"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label'] }
              onChange={ value => { this.setState({ accion: value }); } }
              placeholder="Seleccione un tipo de acción"
              staticData={ [
                { label: 'Alta Personal', value: 'ALTA_PERSONAL' },
                { label: 'Alta Sector', value: 'ALTA_SECTOR' },
                { label: 'Recupero de contraseña', value: 'MODIFICACION' },
                { label: 'Suspensión Cuenta', value: 'SUSPENSION' },

              ] }
              rules={ [{ required: true, message: 'Este campo es requerido' }] }
            />

            { /*
                <Select
                  
                  onChange={ value => { this.setState({ accion: value }); } }
                  placeholder="Seleccione una opción"
                  rules={ [{ required: true, message: 'El Tipo de Acción es requerido' }] }
                  
                >
                  <Option value="ALTA_PERSONAL">Alta Personal</Option>
                  <Option value="ALTA_SECTOR">Alta Sector</Option>
                  <Option value="MODIFICACION">Modificación Cuenta</Option>
                  <Option value="SUSPENSION">Suspensión Cuenta</Option>
                </Select>
                */
            }
            
            
            { accion == 'ALTA_PERSONAL' 
              ? this.getNombreInput(accion) 
              : <span />
            }
            { accion == 'ALTA_PERSONAL' 
              ? this.getApellidoInput(accion) 
              : <span />
            }
            { accion == 'ALTA_SECTOR' 
              ? this.getNombreSectorInput(accion) 
              : <span />
            }
            { accion == 'ALTA_PERSONAL'
              ? this.getLegajoInput()
              : <span />
            }
            { accion == 'MODIFICACION' || accion == 'SUSPENSION'
              ? this.getCorreoInput() 
              : <span />
            }
            { accion == 'MODIFICACION'
              ? this.getTipoModifSelect() 
              : <span />
            }
            { accion == 'MODIFICACION' || accion == 'SUSPENSION'
              ? this.getMotivoInput() 
              : <span />
            }
            { accion == 'ALTA_PERSONAL' || accion == 'ALTA_SECTOR'
              ? this.getComentarioInput() 
              : <span />
            }
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de Correo Electronico` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteCorreoElectronicoStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteCorreoElectronicoStep;
