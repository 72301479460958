import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import secretariasJson from '../../secretarias.json';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import CheckBoxItem from '../../../../shared/components/genericForm/formItems/CheckBoxItem';

import representantes from '../../representantes.json';
import secretarios from '../../secretarios.json';

const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteIncidentesStep extends React.Component {
  state = {
    tramiteSeleccionado: "NINGUNO",
    accion:'',
    incidente:'',
    listadoIncidentes: []
  };

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let assigned;
    let parent;
    let watchers = null; 
    
      
    

    if(formData.tipoIncidente == 'RIA') {
      assigned = '332';
      parent = '10937';
      watchers = ',"watcher_user_ids":[478]';
    } else if( formData.tipoIncidente == 'CAMPUS_VIRTUAL' ) {
      assigned = '320';
      parent = '10940';
      watchers = ',"watcher_user_ids":[478]';
    } 
    else if( formData.tipoIncidente == 'BIBLIOTECA_ELECTRONICA' ) {
      assigned = '328';
      parent = '10937';
    } else if( formData.tipoIncidente == 'ZOOM' ) {
      assigned = '332';
      parent = '9594';
      watchers = ',"watcher_user_ids":[325]';
    } else if( formData.tipoIncidente == 'RUT' ) {
      assigned = '330';
      parent = '10938';
      watchers = ',"watcher_user_ids":[327,478]';
    } else if (formData.tipoIncidente == 'OTRO') {
      assigned = '478';
      parent = '10937';
    } else if (formData.tipoIncidente == 'VPN_RESTABLECER_PASSWORD') {
      assigned = '327';
      parent = '10941';
      watchers = ',"watcher_user_ids":[330]';
    } else if (formData.tipoIncidente == 'SERVICIO_NO_DISPONIBLE') {
      assigned = '327';
      parent = '9591';
      watchers = ',"watcher_user_ids":[320,478,490]';
    } else if (formData.tipoIncidente == 'SIED') {
      assigned = '434';
      parent = '11894';
      watchers = ',"watcher_user_ids":[320,478]';
    } else {
      assigned = '327';
      parent = '9591';
    }
    let tituloPeticion = '';
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    tituloPeticion = 'Incidente ' + formData.tipoIncidente.replace(/_/g,' ') + (formData.tipoFacultad ? ( ' - ' + formData.tipoFacultad) : '');
    descripcionPeticion += `\\n   -------- Datos del Responsable del Tramite --------`;
    descripcionPeticion += `\\n  Nombre: ${this.props.solicitante.nombre}`;
    descripcionPeticion += `\\n  Email: ${this.props.solicitante.email}`;
    descripcionPeticion += `\\n  --------------- Datos del TRAMITE -----------------`;
    descripcionPeticion += `\\n  Tipo de Incidente: ${formData.tipoIncidente.replace('_', ' ')}`;

    if(formData.tipoFacultad)
      descripcionPeticion += ` \\n Sede: ${formData.tipoFacultad || '-'}`;

    if(formData.incidente)
      descripcionPeticion += ` \\n Incidente: ${formData.incidente || '-'}`;

    if(formData.telcontacto)
      descripcionPeticion += ` \\n Telefono de Contacto: ${formData.telcontacto || '-'}`;


    if (formData.energia) {
      descripcionPeticion += ` \\n Verificación de ENERGIA: SI`; 
    }

    if (formData.equipos_reiniciados) {
      descripcionPeticion += ` \\n Verificación Reinicio de equipos: SI`; 
    }



    if(formData.descripcion)
      descripcionPeticion += ` \\n Descripcion del Incidente: ${formData.descripcion || '-'}`;
 
    const tramiteTipo = 'INCIDENTES';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": ${ parent },
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": ${ assigned },
        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
        ${ watchers || ''}
        
      }
    }`;

    
    if (formData.tipoIncidente == 'RUT' && formData.incidente == 'FUERA_DE_SERVICIO' && !formData.energia) {
      alert("Debe verificar que hay energía en el sitio");
      return false;
    }

    if (formData.tipoIncidente == 'RUT' && formData.incidente == 'FUERA_DE_SERVICIO' && !formData.equipos_reiniciados) {
      alert("Debe verificar que los equipos se reiniciaron");
      return false;
    }
      



    
    this.props.actions.saveTramite(
      { tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email },
      () => { this.props.actions.nextStep(); }
    );
    
    
    

  }

  changeTipoIncidente= value => {
   
    this.setState({
      accion: value
    });

  }

  changeIncidente= value => {
   
    this.setState({
      incidente: value
    });

  }
  componentDidMount() {
    let emailSolicitante = this.props.solicitante.email
    let elemento = representantes.find(elem => elem.email == emailSolicitante )
    let elementoSecretarios = secretarios.find(elem => elem.email == emailSolicitante )
    let listado = []
    if(elemento || elementoSecretarios) {
      listado = [
        { label: 'Servicio No Disponible', value: 'SERVICIO_NO_DISPONIBLE' },
        { label: 'Zoom', value: 'ZOOM' },
        { label: 'Campus Virtual', value: 'CAMPUS_VIRTUAL' },
        { label: 'VPN-Restablecer Password', value: 'VPN_RESTABLECER_PASSWORD' },
        { label: 'RUT', value: 'RUT' },
        { label: 'RIA', value: 'RIA' },
        { label: 'Biblioteca Electrónica', value: 'BIBLIOTECA_ELECTRONICA' },
        { label: 'Red Interna Rectorado', value: 'RED_INTERNA_RECTORADO ' },
        { label: 'Red WIFI Rectorado', value: 'RED_WIFI_RECTORADO' },
        { label: 'SIED', value: 'SIED' },          
        { label: 'Otro', value: 'OTRO' },
      ]
    } else {
      listado =  [
        { label: 'Zoom', value: 'ZOOM' },
        { label: 'Campus Virtual', value: 'CAMPUS_VIRTUAL' },
        { label: 'VPN-Restablecer Password', value: 'VPN_RESTABLECER_PASSWORD' },
        { label: 'RIA', value: 'RIA' },
        { label: 'Biblioteca Electrónica', value: 'BIBLIOTECA_ELECTRONICA' },
        { label: 'Red Interna Rectorado', value: 'RED_INTERNA_RECTORADO ' },
        { label: 'Red WIFI Rectorado', value: 'RED_WIFI_RECTORADO' }, 
        { label: 'SIED', value: 'SIED' },   
        { label: 'Otro', value: 'OTRO' },
      ]
    }

    this.setState({
      listadoIncidentes: listado
    })
      

  }

  render() {
    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20} }>
          <Form 
            onSave={ this.handleSave }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
            { this.state.listadoIncidentes && this.state.listadoIncidentes.length > 0 ?
              <SelectItem
                label="Tipo de Incidente"
                name="tipoIncidente"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                onChange={ this.changeTipoIncidente }
                staticData={ this.state.listadoIncidentes }
                rules={ [{ required: true, message: 'El tipo de incidente es requerido' }] }
              /> : <span/>
            }

            { this.state.accion == 'CAMPUS_VIRTUAL' || this.state.accion == 'ZOOM' || this.state.accion == 'RUT' ?
              <SelectItem
                label="Sede"
                name="tipoFacultad"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ secretariasJson }
                rules={ [{ required: true, message: 'La Sede es requerida' }] }
              /> : <span />

            }

            { this.state.accion == 'RUT' ?  
              <SelectItem
                label="Incidente"
                name="incidente"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                onChange={ this.changeIncidente }
                staticData={ [
                  { label: 'Fuera de Servicio', value: 'FUERA_DE_SERVICIO' },
                  { label: 'Servicio Degradado', value: 'SERVICIO_DEGRADADO' },
                  
                ] }
                rules={ [{ required: true, message: 'El incidente es requerido' }] }
              /> : <span />
            }
            { this.state.accion == 'RUT' ||  this.state.accion == 'SERVICIO_NO_DISPONIBLE' ?  
              <InputItem
                type="number"
                label={ "Teléfono de Contacto" }
                name="telcontacto"
                rules={ [{ required:true, message:"El teléfono de cotacto es requerido" }] }
              /> : <span />
            }
          
            { this.state.accion == 'RUT' && this.state.incidente == 'FUERA_DE_SERVICIO' ?  
              <CheckBoxItem
                label="Confirma que hay ENERGIA en el sitio"
                name="energia"
                rules={ [{ required:true, message:"Debe verificar que hay energía en el sitio" }] }
              /> : <span />
            }
            { this.state.accion == 'RUT' && this.state.incidente == 'FUERA_DE_SERVICIO' ? 
              <CheckBoxItem
                label="Confirma que los equipos fueron REINICIADOS"
                name="equipos_reiniciados"
                rules={ [{ required:true, message:"Debe verificar que los equipos se reiniciaron" }] }
              /> : <span />
            }
            
            <TextAreaItem
              label="Descripcion del Incidente"
              name="descripcion"
              rules={ [{ required: true, message: 'Debe ingresar detalles del incidente' }] }
            />
            
             
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Incidentes` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteIncidentesStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteIncidentesStep;
