import request from '../../../shared/helpers/request';
import * as actionsTypes from './usuariosConstants';
 
   
// Search Users 
export function searchingUsers() {
  return { type: actionsTypes.SEARCHING_USERS };
}

export function searchUsersSuccess(data) {
  return { type: actionsTypes.SEARCH_USERS_SUCCESS, data };
}

export function searchUsersFailure(errorMessage) {
  return { type: actionsTypes.SEARCH_USERS_FAILURE, errorMessage };
}

export function searchUsers() {
  return function (dispatch) {
    dispatch(searchingUsers());
    return request
      .get(
        `authentication/users`,
        null,
        dispatch
      )
      .then(
        response => {
          dispatch(searchUsersSuccess(response.data));
        },
        error => {
          let message;
          try {
            message = error.data.errMsg ? error.data.errMsg : "Ocurrio un error...";
          } catch (e) {
            message = "Ocurrio un error...";
          }
          dispatch(searchUsersFailure(message));
          throw error;
        }
      );
  };
}

// Search only ONE user

export function searchingUser() {
  return { type: actionsTypes.SEARCHING_USER };
}

export function searchUserSuccess(data) {
  return { type: actionsTypes.SEARCH_USER_SUCCESS, data };
}

export function searchUserFailure(errorMessage) {
  return { type: actionsTypes.SEARCH_USER_FAILURE, errorMessage };
}

export function searchUser(userId = '') {

  if(userId == ''){ 
    console.error('[searchUser] el userID no puede ser NULL'); 
    throw { message:'[searchUser] el userID no puede ser NULL' };
  }
  
  return function (dispatch) {
    dispatch(searchingUser());
    return request
      .get(
        `authentication/users/${userId}`,
        null,
        dispatch
      )
      .then(
        response => {
          dispatch(searchUserSuccess(response.data));
        },
        error => {
          let message;
          try {
            message = error.data.errMsg ? error.data.errMsg : "Ocurrio un error...";
          } catch (e) {
            message = "Ocurrio un error...";
          }
          dispatch(searchUserFailure(message));
          throw error;
        }
      );
  };
}


// Save User

export function savingUser() {
  return { type: actionsTypes.SAVING_USER };
}

export function saveUserSuccess(data) {
  return { type: actionsTypes.SAVE_USER_SUCCESS, data };
}

export function saveUserFailure(errorMessage) {
  return { type: actionsTypes.SAVE_USER_FAILURE, errorMessage };
}

export function saveUser(values, callbackSave = () => { }) {
  return function (dispatch) {
    dispatch(savingUser());
    const errorCallback = error => {
      dispatch(saveUserFailure(error));
    };
    if (!values.id) {
      return request.post('authentication/users', values, dispatch).then(
        response => {
          dispatch(saveUserSuccess(response.data));
          callbackSave();
        },
        errorCallback
      );
    } else {
      return request.put(`authentication/users/${values.id}`, values, dispatch).then(
        response => {
          dispatch(saveUserSuccess(response.data));
          callbackSave();
        },
        errorCallback
      );
    }
  };
}
 
// Enable User

export function enablingUser() {
  return { type: actionsTypes.ENABLING_USER };
}

export function enableUserSuccess(data) {
  return { type: actionsTypes.ENABLE_USER_SUCCESS, data };
}

export function enableUserFailure(errorMessage) {
  return { type: actionsTypes.ENABLE_USER_FAILURE, errorMessage };
}

export function enableUser(userId = '', callbackEnableUser = () => { }) {
  if (userId == '') {
    console.error('[enableUser] el userID no puede ser NULL');
    throw { message: '[enableUser] el userID no puede ser NULL' };
  }
  return function (dispatch) {
    dispatch(enablingUser());
    const errorCallback = error => {
      dispatch(enableUserFailure(error));
    };
    return request.post(`authentication/users/habilitar/${userId}`, dispatch).then(
      response => {
        dispatch(enableUserSuccess(response.data));
        callbackEnableUser();
      },
      errorCallback
    );
  };
}
 
// Disable User

export function disablingUser() {
  return { type: actionsTypes.DISABLING_USER };
}

export function disableUserSuccess(data) {
  return { type: actionsTypes.DISABLE_USER_SUCCESS, data };
}

export function disableUserFailure(errorMessage) {
  return { type: actionsTypes.DISABLE_USER_FAILURE, errorMessage };
}

export function disableUser(userId = '', callbackDisableUser = () => { }) {
  if (userId == '') {
    console.error('[enableUser] el userID no puede ser NULL');
    throw { message: '[enableUser] el userID no puede ser NULL' };
  }
  return function (dispatch) {
    dispatch(disablingUser());
    const errorCallback = error => {
      dispatch(disableUserFailure(error));
    };
    return request.post(`authentication/users/deshabilitar/${userId}`, dispatch).then(
      response => {
        dispatch(disableUserSuccess(response.data));
        callbackDisableUser();
      },
      errorCallback
    );
  };
}

// Delete User

export function deletingUser() {
  return { type: actionsTypes.DELETING_USER };
}

export function deleteUserSuccess(data) {
  return { type: actionsTypes.DELETE_USER_SUCCESS, data };
}

export function deleteUserFailure(errorMessage) {
  return { type: actionsTypes.DELETE_USER_FAILURE, errorMessage };
}

export function deleteUser(userId = '', callbackDeleteUser = () => { }) {
  if (userId == '') {
    console.error('[deleteUser] el userID no puede ser NULL');
    throw { message: '[deleteUser] el userID no puede ser NULL' };
  }
  return function (dispatch) {
    dispatch(deletingUser());
    const errorCallback = error => {
      dispatch(deleteUserFailure(error));
    };
    return request.delete(`authentication/users/${userId}`, dispatch).then(
      response => {
        dispatch(deleteUserSuccess(response.data));
        callbackDeleteUser();
      },
      errorCallback
    );
  };
}

// Clean Selected User
export function cleanSelectedUser() {
  return { type: actionsTypes.CLEAN_SELECTED_USER };
}
