import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import FormularioComponent from './components/formulario/Formulario';
import ListadoComponent from './components/listado/Listado';
import * as userActions from '../../../redux/sistema/usuarios/usuariosActions';

function mapStateToProps(state) {
  return {
    searchingUsers: state.usuarios.searchingUsers,
    searchingUser: state.usuarios.searchingUser,
    savingUser: state.usuarios.savingUser,
    enablingUser: state.usuarios.enablingUser,
    disablingUser: state.usuarios.disablingUser,
    deletingUser: state.usuarios.deletingUser,
    errorMessage: state.usuarios.errorMessage,
    users: state.usuarios.users,
    selectedUser: state.usuarios.selectedUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, userActions),
      dispatch
    )
  };
}

export const Listado = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListadoComponent)
);

export const Formulario = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FormularioComponent)
);
