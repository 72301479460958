import assignIfExistRule from './customRuleIfExist';
import assignIfNotExistRule from './customRuleIfNotExist';
import assignRuleCheckUTNDomainInMail from './customRuleCheckUTNDomainInMail';
import assignIfIsSecretarioRule from './customRuleCheckIfIsSecretario';
import customRuleRectoradoEmail from './customRuleRectoradoEmail';
import assignCheckHorarioVideo from './customRuleCheckHorarioVideo';
import assignCheckDuracion from './customRuleDuracion';
import assignCheckFinde from './customRuleCheckFinde';
import assignIfIsRepresentanteRule from './customRuleCheckIfIsRepresentante';
import assignIfIsDecanoRule from './customRuleCheckIfIsDecano';
import assignCheckHorarioFirma from './customRuleCheckHorarioFirma';
import assignCheckMaVi from './customRuleCheckMaVi';
import assignIfIsSiedRule from './customRuleCheckIfIsSied';

const mergeCustomRules = (rules, isFormEdition) => {
  let combinedRules = [];
  if(!rules) return combinedRules;
  rules.forEach(rule => {
    if(rule.type){
      switch (rule.type.toUpperCase()) {
        case 'CUSTOM_RULE_IF_EXIST':
          if (!isFormEdition)
            combinedRules.push(assignIfExistRule(rule.params, rule.message));
          break;
        case 'CUSTOM_RULE_IF_NOT_EXIST':
          if (!isFormEdition)
            combinedRules.push(assignIfNotExistRule(rule.params, rule.message));
          break;
        case 'CHECK_UTN_DOMAIN':
          combinedRules.push(assignRuleCheckUTNDomainInMail(rule.params, rule.message));
          break;
        case 'CHECK_RECTORADO_EMAIL':
          combinedRules.push(customRuleRectoradoEmail(rule.params, rule.message));
          break;
        case 'CHECK_SECRETARIO_EMAIL':
          combinedRules.push(assignIfIsSecretarioRule(rule.params, rule.message));
          break;
        case 'CHECK_REPRESENTANTE_EMAIL':
          combinedRules.push(assignIfIsRepresentanteRule(rule.params, rule.message));
          break;
        case 'CHECK_DECANO_EMAIL':
          combinedRules.push(assignIfIsDecanoRule(rule.params, rule.message));
          break;  
        case 'CHECK_HORARIO_VIDEO':
          combinedRules.push(assignCheckHorarioVideo(rule.params, rule.message));
          break;
        case 'CHECK_DURACION':
          combinedRules.push(assignCheckDuracion(rule.params, rule.message));
          break;
        case 'CHECK_FINDE':
          combinedRules.push(assignCheckFinde(rule.params, rule.message));
          break;
        case 'CHECK_MAVI':
          combinedRules.push(assignCheckMaVi(rule.params, rule.message));
          break;

        case 'CHECK_HORARIO_FIRMA':
          combinedRules.push(assignCheckHorarioFirma(rule.params, rule.message));
          break;
        case 'CHECK_SIED_EMAIL':
          combinedRules.push(assignIfIsSiedRule(rule.params, rule.message));
          break;  
       
        default:
          combinedRules.push(rule);
          break;
      }
    }else{
      combinedRules.push(rule);
    }
  });
  return combinedRules;
};

export default mergeCustomRules;