export const SET_TRAMITE_SELECCIONADO = 'SET_TRAMITE_SELECCIONADO';
export const SET_SOLICITANTE = 'SET_SOLICITANTE';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_PREVIOUS_STEP = 'SET_PREVIOUS_STEP';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';

export const SAVING_TRAMITE = 'SAVING_TRAMITE';
export const SAVE_TRAMITE_SUCCESS = 'SAVE_TRAMITE_SUCCESS';
export const SAVE_TRAMITE_FAILURE = 'SAVE_TRAMITE_FAILURE';

