import { connect } from 'react-redux';
import AppContent from './AppContent';

const mapStateToProps = state => (
  {
    user: state.login.user
  }
);

export default connect(mapStateToProps, null)(AppContent);
