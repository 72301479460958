import axios from 'axios';
import AuthenticationHelper from './authenticationHelper';
import { logoutSuccess } from '../../redux/auth/authActions';
import store from '../../redux/store';
import { notification } from 'antd';

axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;

axios.interceptors.request.use(config => {
  config.headers = AuthenticationHelper.isJwtTokenStored() ? { ...config.headers, Authorization: `Bearer ${AuthenticationHelper.getJwtToken()}` } : config.headers;
  return config;
});

axios.interceptors.response.use(
  response => {
    if(response.data.ValidationErrors){
      for (const validationType in response.data.ValidationErrors) {
        const validationMessage = response.data.ValidationErrors[validationType]; 
        notification["error"]({
          message: "Error de Validacion",
          description: validationMessage
        });
      }
    }
    return response;
  },
  error => {
    console.debug('ERROR: ' + error);
    if(error.response){
      if (error.response.status === 401) {
        store.dispatch(logoutSuccess());
        return Promise.reject(error);
      }

      if (error.response.status === 500) {
        let message;
        try {
          message = error.response.data.error ? error.response.data.error : 'Ocurrio un error...';
        } catch (e) {
          message = 'Ocurrio un error...';
        }
        notification['error']({
          message: 'Error',
          description: message,
        });
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default class Request {
  static get(path, dispatch) {
    return axios.get(path, { dispatch });
  }

  static post(path, data = {}, dispatch) {
    return axios.post(path, data, { dispatch });
  }

  static postMultipart(path, data = {}, dispatch) {
    return axios.post(path, data, { dispatch, headers: {'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static postURLEncoded(path, data = {}, dispatch) {
    return axios.post(path, data, { dispatch, headers: { 'Content-Type': `application/x-www-form-urlencoded` } });
  }

  static put(path, data = {}, dispatch) {
    return axios.put(path, data, { dispatch });
  }

  static delete(path, dispatch) {
    return axios.delete(path, { dispatch });
  }

  static patch(path, data = {}, dispatch) {
    return axios.patch(path, data, { dispatch });
  }
}
