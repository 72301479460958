import BackendUtilities from '../../../helpers/backendUtilities';

const assignIfNotExistRule = (params, message = 'Este valor no existe en nuestra base de datos.') => {
  const ifNotExistRule = (rule, value, callback) => {
    BackendUtilities.checkIfExist(
      {
        module: params.module,
        model: params.model,
        column: params.column,
        value: value
      },
      response => {
        if (response) {
          callback(message);
        } else {
          callback();
        }
      }
    );
  };
  return ifNotExistRule;
};

export default assignIfNotExistRule;