import request from '../../shared/helpers/request';
import * as actionsTypes from './authConstants';

export function logingIn() {
  return { type: actionsTypes.PERFORMING_LOGIN };
}

export function loginSuccess(data) {
  return { type: actionsTypes.LOGIN_SUCCESS, data };
}

export function loginFailure(errorMessage) {
  return { type: actionsTypes.LOGIN_FAILURE, errorMessage };
}

export function login(user) {
  return function (dispatch) {
    dispatch(logingIn());
    return request.post('authentication/authenticate', { email: user.user, password: user.password }, dispatch).then(response => {
      dispatch(loginSuccess(response.data));
    }, error => {console.debug(error.response);
      let message;
      try {
        if(error.response.data.error === 'invalid_credentials'){
          message = 'Usuario o contraseña incorrectos';
        }
        if (error.response.data.error === 'user_not_confirmed_yet') {
          message = 'El acceso para este Usuario se encuentra inhabilitado';
        }
        else{
          message = error.response.data.error ? error.response.data.error : 'Ocurrio un error...';
        }
      } catch (e) {
        message = 'Ocurrio un error...';
      }
      dispatch(loginFailure(message));
      throw (error);
    });
  };
}

export function logingOut() {
  return { type: actionsTypes.PERFORMING_LOGOUT };
}

export function logoutSuccess() {
  return { type: actionsTypes.LOGOUT_SUCCESS };
}

export function logoutFailure(message) {
  return { type: actionsTypes.LOGOUT_FAILURE, message };
}

export function logout() {
  return function (dispatch) {
    dispatch(logoutSuccess());
  };
}

export function recoveringPassword() {
  return { type: actionsTypes.PERFORMING_RECOVER };
}

export function recoverSuccess(data) {
  return { type: actionsTypes.RECOVER_SUCCESS, data };
}

export function recoverFailure(errorMessage) {
  return { type: actionsTypes.RECOVER_FAILURE, errorMessage };
}

export function recoverPassword(user) {
  return function (dispatch) {
    dispatch(recoveringPassword());
    return request.post('authentication/user-forgotpassword', { email: user }, dispatch).then(
      response => {
        dispatch(recoverSuccess(response.data));
      },
      error => {
        dispatch(recoverFailure(error));
      }
    );
  };
}

export function gettingUserPermissions() {
  return { type: actionsTypes.GETTING_USER_PERMISSIONS };
}

export function getUserPermissionsSuccess(data) {
  return { type: actionsTypes.GET_USER_PERMISSIONS_SUCCESS, data };
}

export function getUserPermissionsFailure(errorMessage) {
  return { type: actionsTypes.GET_USER_PERMISSIONS_FAILURE, errorMessage };
}

export function getUserPermissions(userId = '') {
  //if UserID is '' the Backend will retrieve defaults permissions.
  
  return function(dispatch) {
    dispatch(gettingUserPermissions());
    return request
      .get(
        `authentication/get-user-permissions/${userId}`,
        null,
        dispatch
      )
      .then(
        response => {
          dispatch(getUserPermissionsSuccess(response.data));
        },
        error => {
          let message;
          try {
            message = error.data.errMsg ? error.data.errMsg : "Ocurrio un error...";
          } catch (e) {
            message = "Ocurrio un error...";
          }
          dispatch(getUserPermissionsFailure(message));
          throw error;
        }
      );
  };
}

export function updatingUserPermissions() {
  return { type: actionsTypes.UPDATING_USER_PERMISSIONS };
}

export function updateUserPermissionsSuccess(data) {
  return { type: actionsTypes.UPDATE_USER_PERMISSIONS_SUCCESS, data };
}

export function updateUserPermissionsFailure(errorMessage) {
  return { type: actionsTypes.UPDATE_USER_PERMISSIONS_FAILURE, errorMessage };
}

export function updateUserPermissions(userId, userPermissions) {
  return function (dispatch) {
    dispatch(updatingUserPermissions());
    return request
      .post(
        `authentication/update-user-permissions/${userId}`,
        { permisos: userPermissions},
        dispatch
      )
      .then(
        response => {
          dispatch(updateUserPermissionsSuccess(response.data));
        },
        error => {
          let message;
          try {
            message = error.data.errMsg ? error.data.errMsg : "Ocurrio un error...";
          } catch (e) {
            message = "Ocurrio un error...";
          }
          dispatch(updateUserPermissionsFailure(message));
          throw error;
        }
      );
  };
}

export function searchingUsers() {
  return { type: actionsTypes.SEARCHING_USERS };
}

export function searchUsersSuccess(data) {
  return { type: actionsTypes.SEARCH_USERS_SUCCESS, data };
}

export function searchUsersFailure(errorMessage) {
  return { type: actionsTypes.SEARCH_USERS_FAILURE, errorMessage };
}

export function searchUsers( userId = '') {
  return function (dispatch) {
    dispatch(searchingUsers());
    return request
      .get(
        `authentication/users/${userId}`,
        null,
        dispatch
      )
      .then(
        response => {
          dispatch(searchUsersSuccess(response.data));
        },
        error => {
          let message;
          try {
            message = error.data.errMsg ? error.data.errMsg : "Ocurrio un error...";
          } catch (e) {
            message = "Ocurrio un error...";
          }
          dispatch(searchUsersFailure(message));
          throw error;
        }
      );
  };
}

export function savingUser() {
  return { type: actionsTypes.SAVING_USER };
}

export function saveUserSuccess(data) {
  return { type: actionsTypes.SAVE_USER_SUCCESS, data };
}

export function saveUserFailure(errorMessage) {
  return { type: actionsTypes.SAVE_USER_FAILURE, errorMessage };
}

export function saveUser(values, callbackSave = () => { }) {
  return function (dispatch) {
    dispatch(savingUser());
    const errorCallback = error => {
      dispatch(saveUserFailure(error));
    };
    if (!values.id) {
      return request.post('authentication/users', values, dispatch).then(
        response => {
          dispatch(saveUserSuccess(response.data));
          callbackSave();
        },
        errorCallback
      );
    } else {
      return request.put(`authentication/users/${values.id}`, values, dispatch).then(
        response => {
          dispatch(saveUserSuccess(response.data));
          callbackSave();
        },
        errorCallback
      );
    }
  };
}

export function gettingUserConfirmationInfo() {
  return { type: actionsTypes.GETTING_USER_CONFIRMATION_INFO };
}

export function getUserConfirmationInfoSuccess(data) {
  return { type: actionsTypes.GET_USER_CONFIRMATION_INFO_SUCCESS, data };
}

export function getUserConfirmationInfoFailure(errorMessage) {
  return { type: actionsTypes.GET_USER_CONFIRMATION_INFO_FAILURE, errorMessage };
}

export function getUserConfirmationInfo( confirmationHash = '', callbackSave = () => { }) {
  return function (dispatch) {
    dispatch(gettingUserConfirmationInfo());

    const errorCallback = error => {
      dispatch(getUserConfirmationInfoFailure(error));
    };

    return request.get(`authentication/get-user-to-confirm/${confirmationHash}`, dispatch).then(
      response => {
        dispatch(getUserConfirmationInfoSuccess(response.data));
        callbackSave();
      },
      errorCallback
    );
  };
}

export function confirmingUser() {
  return { type: actionsTypes.CONFIRMING_USER };
}

export function userConfirmedSuccess(data) {
  return { type: actionsTypes.USER_CONFIRMED_SUCCESS, data };
}

export function userConfirmedFailure(errorMessage) {
  return { type: actionsTypes.USER_CONFIRMED_FAILURE, errorMessage };
}

export function confirmUser(data = {}, callbackSave = () => { }) {
  return function (dispatch) {
    dispatch(confirmingUser());

    const errorCallback = error => {
      dispatch(userConfirmedFailure(error));
    };

    return request.post(`authentication/user-confirmation`,data, dispatch).then(
      response => {
        dispatch(userConfirmedSuccess(response.data));
        callbackSave();
      },
      errorCallback
    );
  };
}

export function enableUser(id, callbackSave = () => { }) {
  console.log("[enableUser] NO Implementado todavia");
  callbackSave(); 
}

export function disableUser(id, callbackSave = () => { }) {
  console.log("[disableUser] NO Implementado todavia");
  callbackSave();
}

export function deleteUser(id, callbackSave = () => { }) {
  console.log("[deleteUser] NO Implementado todavia");
  callbackSave();
}