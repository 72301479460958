import React from 'react';
import { Layout, Card, Steps, Button, PageHeader } from "antd";
//import BreadcrumbRoutes from '../layout/components/AppContent/Routes/BreadcrumbRoutes';
import DatosSolicitanteStep from './components/DatosSolicitanteStep';
//import TramiteVideoConferenciaStep from './components/tramitesComponents/TramiteVideoConferenciaStep';
import ConfirmacionTramiteStep from './components/ConfirmacionTramiteStep';
import TramiteCorreoElectronicoStep from './components/tramitesComponents/TramiteCorreoElectronicoStep';
import TramiteFirmaDigitalStep from './components/tramitesComponents/TramiteFirmaDigitalStep';
//import TramiteClaveTelefonicaStep from './components/tramitesComponents/TramiteClaveTelefonicaStep';
import TramiteTelefonosInternosStep from './components/tramitesComponents/TramiteTelefonosInternosStep';
import TramiteSolicitudLicenciasSoftwareStep from './components/tramitesComponents/TramiteSolicitudLicenciasSoftwareStep';
import TramiteIncidentesStep from './components/tramitesComponents/TramiteIncidentesStep';
import TramiteVPNStep from './components/tramitesComponents/TramiteVPNStep';
import TramiteSiedStep from './components/tramitesComponents/TramiteSiedStep';
import TramiteSoporte from './components/tramitesComponents/TramiteSoporte';
import { Fragment } from 'react';



const { Content } = Layout;
const { Step } = Steps;

const steps = [
  {
    title: 'Datos del Solicitante'
  },
  {
    title: 'Datos del Trámite'
  },
  {
    title: 'Confirmación'
  },
];

const tramites = [
  /*{ id:1, title: 'Video Conferencia'},*/
  { id:2, title: 'Correo Electrónico'},
  { id:3, title: 'Firma Digital'},
  
  { id:5, title: 'Telefonía Rectorado'},
  { id:6, title: 'Licencias de Software'},
  { id:7, title: 'Incidentes'},
  { id:8, title: 'VPN'},
  { id:9, title: 'SIED'},
  { id:10, title: 'Soporte Técnico'}
]

class TramitesPage extends React.Component {
  state = {
    tramiteSeleccionado:null,
    title:null

  }

  componentDidMount() {
    
    let title = null;
    let encontrarIndex = tramites.findIndex(elem => elem.id == parseInt(this.props.match.params.numeroTramite) )
    if(encontrarIndex > -1)
      title =tramites[encontrarIndex].title;

    this.props.actions.setTramiteSeleccionado({ tramiteId: parseInt(this.props.match.params.numeroTramite), title: title});

    this.props.actions.setCurrentStep(0);

    
    
  }

  renderCurrentStep = currentStep => {
    if(currentStep === 0){
      return (
        <Fragment>
          {this.props.tramiteSeleccionado.tramiteId !== 8 &&<DatosSolicitanteStep { ...this.props }/>}
          {this.props.tramiteSeleccionado.tramiteId == 8 &&
            <Card style={ { marginTop: 20, marginBottom: 20} }>
              <div>
                <p>Para utilizar el servicio VPN, por favor consulte al siguiente instructivo.</p>
                <a href='https://utn.edu.ar/images/Secretarias/TIC/Instructivos/Instalacin-VPN.pdf' target='_blank'>Instructivo VPN</a>
              </div>
            </Card>
          }
          
        </Fragment>
      );
    }
    if (currentStep === 1) {
      switch (this.props.tramiteSeleccionado.tramiteId) {
        // case 1:
        // return < TramiteVideoConferenciaStep { ...this.props } />;
        case 2:
          return <TramiteCorreoElectronicoStep { ...this.props } />;
        case 3:
          return <TramiteFirmaDigitalStep { ...this.props } />;
        case 5:
          return <TramiteTelefonosInternosStep { ...this.props } />;
        case 6:
          return <TramiteSolicitudLicenciasSoftwareStep { ...this.props } />;
        case 7:
          return <TramiteIncidentesStep { ...this.props } />;
        case 8:
          return <TramiteVPNStep { ...this.props } />;
        case 9:
          return <TramiteSiedStep { ...this.props } />;  
        case 10:
          return <TramiteSoporte { ...this.props } />;    

        default:
          return <div>No se encontro el Tramite</div>;
      }
    }
    if (currentStep === 2) {
      return <ConfirmacionTramiteStep { ...this.props }/>;
    }
  }

  

  render() {
    return (
     
      

      <Content style={ { margin: '0px 0px' } }>
        { /*
        <div style={ { marginBottom: 17 } }>
          <BreadcrumbRoutes />
        </div>
        */}
        <Card>
          <Steps current={ this.props.currentStep }>
            {
              steps.map(item => (
                <Step key={ item.title } title={ item.title } />
              ))
            }
          </Steps>
            
          <div className="steps-content">
            <div className="steps-action">
              {(this.props.currentStep == 0 || this.props.currentStep == 1) && (
                <Button style={ { marginLeft: 15,marginTop:15 } } onClick={ () => {
                  if(this.props.currentStep == 0)
                    this.props.history.push("/")
                  else
                    this.props.actions.previousStep();
                
                } }>
                    Volver
                </Button>
              )}
            </div>
            <PageHeader title={ `Trámite Seleccionado:  ` + this.props.tramiteSeleccionado.title }  /> 
            <p style={ { marginLeft:'2rem',fontSize:'90%' } }>*El tiempo para la resolución de este trámite es { this.props.tramiteSeleccionado.tramiteId == 9 ? 'de una semana':'de 48Hs hábiles' } sujeto a la disponibilidad del servicio</p>
            { this.renderCurrentStep(this.props.currentStep) }
          </div>
          <div className="steps-action">
            {(this.props.currentStep == 0 || this.props.currentStep == 1) && (
              <Button style={ { marginLeft: 8 } } onClick={ () => {
                if(this.props.currentStep == 0)
                  this.props.history.push("/")
                else
                  this.props.actions.previousStep();

              } }>
                  Volver
              </Button>
            )}
          </div>
        </Card>
      </Content>

       
    );
  }
}

export default TramitesPage;
