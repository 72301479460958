import React from 'react';
import QueryString from 'query-string';
import LoginComponent from '../../../../shared/components/login/LoginComponent';
import logoImage from './assets/logo_utn.png';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleRecoverPassword = this.handleRecoverPassword.bind(this);
  }

  componentDidMount() {
    this.checkToRedirect();
  }

  componentDidUpdate() {
    this.checkToRedirect();
  }

  checkToRedirect() {
    if (this.props.user.logged) {
      const redirectPath = this.determineRedirectPath();
      this.props.history.push(redirectPath);
    }
  }

  determineRedirectPath() {
    const urlParams = QueryString.parse(this.props.location.search);
    let redirectPath = null;
    
    if (urlParams.redirect) {
      redirectPath = urlParams.redirect;
    }
    else {
      redirectPath = '/home';
    }

    return redirectPath;
  }

  handleLogin(data) {
    this.props.actions.login(data);
  }

  handleRecoverPassword(data) {
    this.props.actions.recoverPassword(data.user);
  }

  render() {
    return (
      <LoginComponent
        title={ 'Monitoreo RUT' }
        logo={ logoImage }
        handleLogin={ this.handleLogin }
        handleRecoverPassword={ this.handleRecoverPassword }
        isWorking={ this.props.logingIn }
        loginErrorMessage={ this.props.errorMessage }
        recoverErrorMessage={ this.props.errorMessage }
        isRecoveringPasswordSuccessful={ this.props.isRecoverSuccess }
      />
    );
  }

}

export default LoginPage;
