import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Layout,Card } from "antd";
import { Switch, Route } from "react-router-dom";
import { Formulario, Listado } from "./UsuariosPageConnector";
import BreadcrumbRoutes from '../../layout/components/AppContent/Routes/BreadcrumbRoutes';

const { Content } = Layout;

class UsuariosPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Content
          style={ { margin: '24px 16px' } } 
        >
          <div style={ { marginBottom: 17 } }>
            <BreadcrumbRoutes />
          </div>
          <Card
            type="inner"
          >
            <Switch>
              <Route exact path={ `${this.props.match.path}/` } component={ Listado } />
              <Route path={ `${this.props.match.path}/new` } component={ Formulario } />
              <Route path={ `${this.props.match.path}/edit/:id` } component={ Formulario } />
            </Switch>
          </Card>
        </Content>
      </Fragment>

    );
  }
}

UsuariosPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired
};

export default UsuariosPage;
