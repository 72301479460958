import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import BackendUtilities from "../../../../shared/helpers/backendUtilities";
import mergeCustomRules from '../customRules';
import moment from 'moment';

const Option = Select.Option;
const AntFormItem = Form.Item;
const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

class SelectItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectItems:[]
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (this.props.relatedFieldValue !== prevProps.relatedFieldValue) {
      this.fetchItems();
      const value = undefined;
      this.props.form.setFieldsValue({
        [this.props.name]: value
      });
      this.handleOnChange(value);
    }
  }

  fetchItems(callback = () => {}) {
    
    if(this.props.staticData){
      this.setState({ loading: false, selectItems: this.props.staticData });
      callback();
    }else{
      const relatedColumnName = this.props.dependsOnFieldColumnMap ? this.props.dependsOnFieldColumnMap : this.props.dependsOnField;
      const columnsWhereSearch = this.props.dependsOnField ? `${relatedColumnName}::=` : '';
  
      BackendUtilities.getSelectData(
        {
          module: this.props.genericSearchModule,
          model: this.props.genericSearchModel,
          columnsToBeReturned: this.props.genericSearchColumnsToBeReturned,
          columnsToBeOrderBy: this.props.genericSearchColumnsToBeOrderBy,
          textToSearch: this.props.relatedFieldValue ? this.props.relatedFieldValue : '',
          columnsWhereSearch: columnsWhereSearch
        },
        response => {
          this.setState({ loading:false, selectItems: response.data });
          callback();
        }
      );
    }

  }

  isDataField = fieldToShow  => {
    for(let fieldData in this.state.selectItems[0]){
      if(fieldToShow === fieldData){
        return true;
      }
    }
    return false;
  }

  renderValueToShow = item => {
    let valueToAssign = '';
    for (let selectValueIndex in this.props.fieldsValues){
      let selectValue = this.props.fieldsValues[selectValueIndex];
      if(this.isDataField(selectValue))
        valueToAssign += item[selectValue];
      else
        valueToAssign += selectValue;
    }
    return valueToAssign;
  }

  renderLabelToShow = item => {
    let labelToShow = '';
    for (let fieldToShowIndex in this.props.fieldsToShow){
      let fieldToShow = this.props.fieldsToShow[fieldToShowIndex];
      if(this.isDataField(fieldToShow))
        labelToShow += item[fieldToShow];
      else
        labelToShow += fieldToShow;
    }
    return labelToShow;
  }

  renderSelectItems = () => {
    if (this.state.selectItems.length > 0){
      return this.state.selectItems.map(selectItem => 
        (
          <Option value={ this.renderValueToShow(selectItem) } key={ selectItem.id }>
            { this.renderLabelToShow(selectItem) }
          </Option>
        )
      );
    }else{
      return null;
    }
  }

  handleOnChange(value) {
    this.props.onItemChange(this.props.name, value);
  }
  
  render() {
    const { getFieldDecorator,getFieldValue } = this.props.containerForm;
    let itemProps = Object.assign({}, this.props);

    delete itemProps.isFormEdition;
    delete itemProps.containerForm;
    delete itemProps.rules;
    delete itemProps.validateTrigger;
    delete itemProps.label;
    delete itemProps.name;
    delete itemProps.help;
    delete itemProps.hasFeedback;
    delete itemProps.initialValue;
    delete itemProps.genericSearchModule;
    delete itemProps.genericSearchColumnsToBeReturned;
    delete itemProps.genericSearchColumnsToBeOrderBy;
    delete itemProps.fieldsToShow;
    delete itemProps.staticData;
    delete itemProps.fieldsValues;
    delete itemProps.fieldsToShow;

    if(itemProps.horario)
    {
      if(getFieldValue(itemProps.horario))
      {
        this.props.rules[1].params = parseInt(moment(getFieldValue(itemProps.horario)).format("HHmm"))
      }
     
    }

    return (
      <AntFormItem
        style={ { marginBottom: 2 } }
        label= { this.props.label }
        help = { this.props.help }
        { ...this.props.formItemLayout }
      >
        {
          getFieldDecorator(
            this.props.name,
            {
              initialValue: this.props.initialValue,
              rules: mergeCustomRules(this.props.rules),
            }
          )(
            <Select
              loading={ this.state.loading }
              onChange={ this.handleOnChange }
              { ...itemProps }
            >
              { this.renderSelectItems() }
            </Select>
          )
        }
      </AntFormItem>
    );
  }
}

SelectItem.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.string,
  rules: PropTypes.array,
  formItemLayout: PropTypes.object,
  containerForm: PropTypes.object.isRequired,
  genericSearchModule: PropTypes.string,
  genericSearchModel: PropTypes.string,
  genericSearchColumnsToBeReturned: PropTypes.string,
  genericSearchColumnsToBeOrderBy: PropTypes.string,
  fieldsValues: PropTypes.array.isRequired,
  fieldsToShow: PropTypes.array.isRequired,
  dependsOnField: PropTypes.string,
  dependsOnFieldColumnMap: PropTypes.string,
  relatedFieldValue: PropTypes.string,
  onItemChange: PropTypes.func.isRequired,
};

SelectItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  rules: [],
  formItemLayout: defaultFormItemLayout,
  dependsOnField: null,
  dependsOnFieldColumnMap: null,
  onItemChange: () => {
    console.warn("[SelectItem] onItemChange not implemented.");
  },
  relatedFieldValue: undefined
};


export default SelectItem;
