import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
import secretarios from '../../secretarios';

const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteTelefonosInternosStep extends React.Component {
  state = {
    tipoTramite:null,
  }

  handleChangeSolicitud = value => {
    let tipo;
    if(value == 'NUEVO' || value == 'MUDANZA' || value == 'PROBLEMA_FUNCIONAMIENTO')
      tipo = 1
    else if (value =='ASIGNAR_CLAVE')
      tipo = 4
    else if (value =='MODIFICAR_PERMISOS')
      tipo = 5
    else if (value =='BLOQUEAR_CLAVE')
      tipo = 6;
      
    
    this.setState({
      tipoTramite:tipo
    })
  }

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let tituloPeticion = '';
    let objsec= { nombre:'',secretaria:''};
    let objsecparent= { nombre:'',secretaria:'',email:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    let indice = secretarios.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsec = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,parent: secretarios[indice].parent };
    
     
    if(objsec.parent){
      let indice = secretarios.findIndex(elem => elem.id == objsec.parent);
      if(indice > -1)
        objsecparent = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,email: secretarios[indice].email };
    }



    tituloPeticion = 'Trámite Telefonía Rectorado';


    descripcionPeticion += `\\n   -------- Datos del Responsable del Tramite --------`;
    if(objsecparent.email){
      descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
      descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      descripcionPeticion += `\\n  Nombre Autoridad: ` + objsecparent.nombre; 
    } else {
      descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
      descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
    }
    descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
    descripcionPeticion += `\\n  --------------- Datos del TRAMITE -----------------`;
    descripcionPeticion += `\\n  Tipo de Solicitud: ${formData.tipo}`;
    if(this.state.tipoTramite == 1)
    {
      descripcionPeticion += `\\n  Detalle: ${formData.detalle || '-'}`;
    } else {
      descripcionPeticion += `\\n  Legajo: ${formData.legajo }`;
      descripcionPeticion += `\\n  Permisos: ${formData.permisos || '-'}`;
    }
   

    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;

    const tramiteTipo = 'TELEFONOS_INTERNOS';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 9591,
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": 330,
        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
      }
    }`;

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    if(objsecparent.email)
      objetoenviar.emailparent = objsecparent.email;
    
    this.props.actions.saveTramite(
      objetoenviar,
      () => { this.props.actions.nextStep(); }
    );
    

  }

  render() {
    let texto = '';
    switch(this.state.tipoTramite) {
      case 4:
        texto = 'otorgar';
        break;
      case 5:
        texto = 'modificar';
        break;
      case 6:
        texto = 'quitar';
        break;
    }

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form 
            onSave={ this.handleSave }
            isFormEdition={ false }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
            <SelectItem
              onChange={ this.handleChangeSolicitud }
              label="Tipo de Solicitud"
              name="tipo"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label'] }
              staticData={ [
                { label: 'Asignar Interno Nuevo', value: 'NUEVO' },
                { label: 'Mudar Interno Existente', value: 'MUDANZA' },
                { label: 'Problema en Funcionamiento', value: 'PROBLEMA_FUNCIONAMIENTO' },
                { label: 'Asignar Clave', value: 'ASIGNAR_CLAVE' },
                { label: 'Modificar Permisos', value: 'MODIFICAR_PERMISOS' },
                { label: 'Bloquear Clave', value: 'BLOQUEAR_CLAVE' },
                
              ] }
              rules={ [{ required: true, message: 'Este campo es requerido' }] }
            />

            { this.state.tipoTramite == 1 ?
              <InputItem
                label="Detalle"
                name="detalle"
                rules={ [{ required: true, message: 'En este campo debe ingresar lo que se desea solicitar.' }] }

              /> : <span />
            }

            { this.state.tipoTramite >= 4 ?
              [
                <InputItem
                  key="tram1"
                  label="Legajo"
                  name="legajo"
                  rules={ [{ required: true, message: 'El legajo que se solicita es obligatorio' }] }

                />,
          
                <SelectItem
                  key="tram2"
                  label={ "Permisos a " + texto }
                  name="permisos"
                  mode="multiple"
                  fieldsValues={ ['value'] }
                  fieldsToShow={ ['label'] }
                  staticData={ [
                    { label: 'Celular Nacional', value: 'llamada_celular_nacional' },
                    { label: 'Salida Internacional', value: 'llamada_internacional' },
                    { label: 'Salida Nacional', value: 'llamada_nacional' },
                  ] }
                  rules={ [{ required: true, message: 'Este campo es requerido' }] }
                />] : <span />
            }
            <TextAreaItem
              label="Comentario"
              name="comentario"
            />
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de Telefonos Internos` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteTelefonosInternosStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteTelefonosInternosStep;
