import * as actionsTypes from './parametrosConstants';

const parametroInitialValue = {
  id:null,
  clave:'',
  valor:'',
  descripcion:''
};

const initialState = {
  parametros:[],
  parametroSeleccionado: parametroInitialValue,
  errorMessage: '',
  trabajando: false
};
const usableState = Object.assign({}, initialState);

export default function parametrosReducer(state = usableState, action) {
  let editedState;
  switch (action.type) {

    case actionsTypes.WORKING:
      editedState = Object.assign({}, state);
      editedState.trabajando = true;
      break;

    case actionsTypes.FETCH_PARAMETROS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.parametros = action.data;
      break;

    case actionsTypes.FETCH_PARAMETROS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.SAVE_PARAMETROS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      break;

    case actionsTypes.SAVE_PARAMETROS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.errorMessage = action.errorMessage;
      break;
    
    case actionsTypes.DELETE_PARAMETROS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      break;

    case actionsTypes.DELETE_PARAMETROS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.errorMessage = action.errorMessage;
      break;
    case actionsTypes.FETCH_PARAMETRO_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.parametroSeleccionado = action.data;
      break;

    case actionsTypes.FETCH_PARAMETRO_FAILURE:
      editedState = Object.assign({}, state);
      editedState.trabajando = false;
      editedState.errorMessage = action.errorMessage;
      editedState.parametroSeleccionado = parametroInitialValue;
      break;
    
    case actionsTypes.CLEAN_SELECTED:
      editedState = Object.assign({}, state);
      editedState.parametroSeleccionado = parametroInitialValue;
      break;

    default:
      return state;
  }
  return editedState;
}
