import representantes from '../../../../pages/tramites/representantes';
import secretarios from '../../../../pages/tramites/secretarios';
import autorizadosLicencias from '../../../../pages/tramites/autorizadosLicencias';

const assignIfIsRepresentanteRule = (params, message = 'El email debe pertenecer a un Representante TIC o Autoridad de Rectorado.') => {
  const checkIfIsRepresentante = (rule, value, callback) => {
    representantes.map(representante => {
      if(representante.email === value){
        callback();    
      }
    });
    secretarios.map(representante => {
      if(representante.email === value){
        callback();    
      }
    });
    autorizadosLicencias.map(autoridad => {
      if(autoridad.email === value){
        callback();    
      }
    });
    callback(message);
  };
  return checkIfIsRepresentante;
};

export default assignIfIsRepresentanteRule;