import React, { Component } from 'react';

class FooterUtn extends Component {

  render() {
    return (
      <section id="sp-bottom">
        <div className="container">
          <div className="container-inner">
            <div className="row">
              <div id="sp-bottom1" className="col-lg-2 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                      <div className="custom">
                        <p><img src="https://utn.edu.ar/images/logoutnwhite.png" alt="" width="189" height="49" /></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }>UTN | Rectorado</p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a>Sarmiento 440&nbsp;(C1041AAJ)</a></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a>Buenos Aires, Argentina</a></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a href="tel: +54 11 5371 5600">+54 11 5371 5600</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sp-bottom2" className="col-sm-col-sm-6 col-lg-7 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FooterUtn;
