import React from "react";
import PropTypes from "prop-types";
import { Layout, Row, Col } from "antd";
import GenericTable from "../../../shared/components/genericTable/GenericTable";
import GenericSearch from "../../../shared/components/genericSearch/GenericSearch";

const { Content } = Layout;

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    width: "20%"
  },
  {
    title: 'Parametro',
    dataIndex: 'clave',
    width: '30%',
  }, {
    title: 'Valor',
    dataIndex: 'valor',
    width: '20%',
  }, {
    title: 'Descripcion',
    width: '50%',
    dataIndex: 'descripcion',
  }
];

class Listado extends React.Component {
  state = {
    parametros: []
  };

  componentWillMount() {
    this.props.actions.fetchParametros();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.parametros !== this.state.parametros) {
      this.setState({ parametros: this.props.parametros });
    }
  }

  handleDeleteClick = parametro => {
    this.props.actions.eliminarParametro(
      parametro.id,
      this.props.actions.fetchParametros
    );
  };

  handleSearch = parametros => {
    this.setState({ parametros: parametros });
  };

  render() {
    return (
      <React.Fragment>
        <Content style={ { margin: 24 } }>
          <Row gutter={ 16 }>
            <Col xs={ 24 } md={ 24 } lg={ 24 }>
              <GenericSearch
                backendModule={ "application" }
                backendEntity={ "configuracion" }
                columnsWhereSearch={ ["clave", "valor", "descripcion"] }
                columnsToBeReturned={ columns }
                orderByColumn={ "id" }
                onSearch={ this.handleSearch }
              />
              <GenericTable
                columns={ columns }
                entities={ this.state.parametros }
                loading={ this.props.trabajando }
                newButtonUri={ "parametros/new" }
                editButtonUri={ "parametros/edit" }
                onDeleteRecord={ this.handleDeleteClick }
                onChange={ this.handleSelectionChanged }
                debug={ true }
              />
            </Col>
          </Row>
        </Content>
      </React.Fragment>
    );
  }
}

Listado.propTypes = {
  trabajando: PropTypes.bool.isRequired,
  parametros: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    fetchParametros: PropTypes.func,
    guardarParametro: PropTypes.func,
    eliminarParametro: PropTypes.func,
  }).isRequired
};

Listado.defaultProps = {
  trabajando: false,
  parametros:[]
};
 
export default Listado;
