const axios = require("axios");

export default class BackendUtilities {
 
  static checkIfExist = (apiParams, callback) => {
    axios
      .get("/application/exist", {
        params: {
          module: apiParams.module,
          model: apiParams.model,
          column: apiParams.column,
          value: apiParams.value
        }
      })
      .then( response => {
        callback(response.data.exist);
      })
      .catch( error => {
        console.error('Error checkeando si existe en backend');
        console.error('Parametros del API: ', apiParams);
        console.error('Error Object: ', error);
      });
  };

  static quickUpdate = (apiParams, callback) => {
    axios
      .get("/application/quick-update", {
        params: {
          module: apiParams.module,
          model: apiParams.model,
          column: apiParams.column,
          value: apiParams.value,
          modelId: apiParams.modelId
        }
      })
      .then( response => {
        callback(response.data.exist);
      })
      .catch( error => {
        console.error('Error haciendo un quick-update en el backend');
        console.error('Parametros del API: ', apiParams);
        console.error('Error Object: ', error);
      });
  };

  static genericSearch = (apiParams, callback) => {
    axios
      .get("/application/generic-search", {
        params: {
          module: apiParams.module,
          model: apiParams.model,
          textToSearch: apiParams.textToSearch,
          columnsToBeReturned: apiParams.columnsToBeReturned,
          columnsWhereSearch: apiParams.columnsWhereSearch,
          columnsToBeOrderBy: apiParams.columnsToBeOrderBy,
          pageSize: apiParams.pageSize,
          page: apiParams.page
        }
      })
      .then(function(response) {
        callback(response.data);
      })
      .catch(error => {
        console.error("Error en la busqueda generica en backend");
        console.error("Parametros del API: ", apiParams);
        console.error("Error Object: ", error);
      });
  };

  static getSelectData = (apiParams, callback) => {
    axios
      .get("/application/generic-search", {
        params: {
          module: apiParams.module,
          model: apiParams.model,
          columnsToBeReturned: apiParams.columnsToBeReturned,
          columnsToBeOrderBy: apiParams.columnsToBeOrderBy,
          textToSearch: apiParams.textToSearch,
          columnsWhereSearch: apiParams.columnsWhereSearch
        }
      })
      .then(function(response) {
        callback(response.data);
      })
      .catch(error => {
        console.error("Error en la busqueda generica en backend");
        console.error("Parametros del API: ", apiParams);
        console.error("Error Object: ", error);
      });
  };
 
}