import React from 'react';
import { Layout, Card,Row, Col } from 'antd';
import PropTypes from 'prop-types';
import TipoSolicitudesStep from '../tramites/components/TipoSolicitudesStep';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tramitesActions from '../../redux/tramites/tramitesActions';
import BuscarComponent from './BuscarComponent';






const { Content } = Layout;

class HomePage extends React.Component {
  state = {
    horas: 24
  };

  handleChangeHoras = evt =>
    this.setState({ horas: evt.target.value });

  getEpochTime = () => {
    const epochTime = (new Date).getTime();
    return epochTime.toString().substring(0, 10);
  }

  
  

  render() {
   
    return (
      <Content style={ { margin: '0px 0px' } }>
        <TipoSolicitudesStep { ...this.props }/>

        <Row gutter={ 24 } >
           
          <Col lg={ 24 } md={ 24 } xs={ 24 }>
            <Card title="Buscar Trámite" style={ { marginTop: 20, marginBottom: 20, height: '100%'} }>
              <Row gutter={ 16 } >
                <Col lg={ 24 } md={ 24 } xs={ 24 }>
                 
                  <BuscarComponent  { ...this.props } />

                </Col>
              </Row>

            </Card>
          </Col>
        </Row>
          

      </Content>
    );
  }
}

HomePage.propTypes = {
  trabajando: PropTypes.bool
};


function mapStateToProps(state) {
  return {
    currentStep: state.tramites.currentStep,
    tramiteSeleccionado: state.tramites.tramiteSeleccionado, 
    solicitante: state.tramites.solicitante,
    codigoTramiteGenerado: state.tramites.codigoTramiteGenerado,
    savingTramite: state.tramites.savingTramite
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(tramitesActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
