import React, { Fragment } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import Form from '../../../shared/components/genericForm/Form';
import InputItem from '../../../shared/components/genericForm/formItems/InputItem';

const { Content } = Layout;

class Formulario extends React.Component {

  constructor() {
    super();
  }

  componentWillMount() {
    this.props.actions.limpiarParametroSeleccionado();
    if (this.isEdition()) {
      this.props.actions.fetchParametro(this.props.match.params.id);
    }
  }

  isEdition = () => {
    const { id } = this.props.match.params;
    if (id) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = parametro => {
    if (this.isEdition()) {
      parametro.id = this.props.match.params.id;
    }
    this.props.actions.guardarParametro(parametro, () => { alert('parametro saved'); });
  };

  render() {
    let titulo = this.isEdition() ? "Editar Parametro del Sistema" : "Nuevo Parametro del Sistema";
    return (
      <Fragment>
        <Content>
          <Form
            title={ titulo }
            onSave={ this.handleSave }
            formValues={ this.props.parametroSeleccionado }
            isFormEdition={ this.isEdition() }
          >
            <InputItem
              label="Clave"
              name="clave"
              disableInEdition
              rules={ [
                { required: true, message: 'El nombre es requerido' },
                {
                  type: 'CUSTOM_RULE_IF_EXIST',
                  params: {
                    module: 'Application',
                    model: 'Configuracion',
                    column: 'clave',
                  },
                  message: 'Ya existe esta propiedad en esta aplicacion'
                } 
              ] }
            />
             
            <InputItem
              label="Valor"
              name="valor"
              rules={ [
                { required: true, message: 'El valor para este campo es requerido' },
              ] }

            />
            <InputItem
              label="Descripcion corta"
              name="descripcion"
              rules={ [
                { required: true, message: 'El valor para este campo es requerido' },
              ] }

            />
          </Form>
        </Content>
      </Fragment>
    );
  }
}

Formulario.propTypes = {
  trabajando: PropTypes.bool,
  parametroSeleccionado: PropTypes.object,
  actions: PropTypes.shape({
    fetchParametro: PropTypes.func,
    guardarParametro: PropTypes.func,
    limpiarParametroSeleccionado: PropTypes.func
  }).isRequired

};

export default Formulario;
