import React from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm, Input as AntInput } from 'antd';
import mergeCustomRules from '../customRules';
const AntFormItem = AntForm.Item;

class InputItem extends React.Component {

  render() {
    const { getFieldDecorator } = this.props.containerForm;
    let inputProps = Object.assign({},this.props);

    delete inputProps.isFormEdition;
    delete inputProps.containerForm;
    delete inputProps.rules;
    delete inputProps.validateTrigger;
    delete inputProps.label;
    delete inputProps.help;
    delete inputProps.hasFeedback;
    delete inputProps.ifExistRule;
    delete inputProps.hideText;
    delete inputProps.disableInEdition;
    delete inputProps.formItemLayout;

    let computedProps = { disabled: false };

    if( this.props.disableInEdition && this.props.isFormEdition ){
      computedProps.disabled = true;
    }

    if ( this.props.hideText && this.props.isFormEdition ){
      computedProps.disabled = true;
    }

    let input = (!this.props.hideText) ? <AntInput  { ...inputProps } { ...computedProps }/> : <AntInput.Password { ...inputProps } { ...computedProps } />;
    return (
      <AntFormItem
        style={ { marginBottom: 2 } }
        label= { this.props.label }
        help = { this.props.help }
        { ...this.props.formItemLayout }
      >
        {
          getFieldDecorator(
            this.props.name,
            {
              validateTrigger: this.props.validateTrigger,
              rules: mergeCustomRules(this.props.rules, this.props.isFormEdition),
            }
          )(
            ( input )
          )
        }
      </AntFormItem>
    );
  }
}

InputItem.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validateTrigger: PropTypes.string,
  rules: PropTypes.array,
  formItemLayout: PropTypes.object,
  containerForm: PropTypes.object.isRequired,
  hideText: PropTypes.bool,
  disableInEdition: PropTypes.bool
};

const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

InputItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  placeholder: ' ',
  validateTrigger: 'onBlur',
  rules: [],
  formItemLayout: defaultFormItemLayout,
  hideText: false,
  disableInEdition: false
};



export default InputItem;