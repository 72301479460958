const assignCheckHorarioFirma = (params,message = 'El horario de reserva es de 11 a 16:30Hs') => {
  const checkHorarioFirma = (rule, value, callback) => {
   
    if( value && (value.hour() < 11 || ( value.hour() == 16 && value.minute() > 30) || value.hour() > 16))
    {
      callback(message);
    } else
    { 
      callback();
    }
    
   
  };
  return checkHorarioFirma;
};

export default assignCheckHorarioFirma;