
const assignDuracion = (params,message) => {
  const checkDuracion = (rule, value, callback) => {
    if(params + (parseInt(value) * 100) > 2100)
      callback(message);
    else
      callback();
  };
  return checkDuracion;
};

export default assignDuracion;