import * as actionsTypes from './tramitesConstants';

const initialState = {
  currentStep: 0,
  codigoTramiteGenerado: '',
  tramiteSeleccionado: {
    tramiteId: 0,
    title: 'NINGUNO',
  }, 
  solicitante: { 
    nombre: '',
    email: '',
  }, 
  savingTramite: false
};

const usableState = Object.assign({}, initialState);

export default function appReducer(state = usableState, action) {
  let editedState;
  switch (action.type) {

    case actionsTypes.SET_TRAMITE_SELECCIONADO:
      editedState = Object.assign({}, state);
      editedState.tramiteSeleccionado = action.tramite;
      break;

    case actionsTypes.SET_SOLICITANTE:
      editedState = Object.assign({}, state);
      editedState.solicitante = action.solicitante;
      break;

    case actionsTypes.SET_CURRENT_STEP:
      editedState = Object.assign({}, state);
      editedState.currentStep = action.stepID;
      break;
 
    case actionsTypes.SET_PREVIOUS_STEP:
      editedState = Object.assign({}, state);
      editedState.currentStep = state.currentStep - 1;
      break;
 
    case actionsTypes.SET_NEXT_STEP:
      editedState = Object.assign({}, state);
      editedState.currentStep = state.currentStep + 1;
      break;

    case actionsTypes.SAVING_TRAMITE:
      editedState = Object.assign({}, state);
      editedState.savingTramite = true;
      break;

    case actionsTypes.SAVE_TRAMITE_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.savingTramite = false;
      editedState.codigoTramiteGenerado = action.data.gp_peticion_id;
      break;

    case actionsTypes.SAVE_TRAMITE_FAILURE:
      editedState = Object.assign({}, state);
      editedState.savingTramite = false;
      break;
      
    default:
      return state;
  }
  return editedState;
}
