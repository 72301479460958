import * as actionsTypes from './usuariosConstants';
import AuthenticationHelper from '../../../shared/helpers/authenticationHelper';

const initialState = {
  searchingUsers: false,
  searchingUser: false,
  savingUser: false,
  enablingUser: false,
  disablingUser: false,
  deletingUser: false,
  errorMessage: '',
  users:[],
  selectedUser:{
    id: null,
    nombre: null,
    rol: null,
    email: null
  }
};
const usableState = Object.assign({}, initialState);

if (AuthenticationHelper.isJwtTokenStored()) {
  usableState.user = AuthenticationHelper.getDecodedToken();
  usableState.user.logged = true;
}

export default (state = usableState, action) => {
  let editedState;
  switch (action.type) {
     
    case actionsTypes.SEARCHING_USERS:
      editedState = Object.assign({}, state);
      editedState.searchingUsers = true;
      break;

    case actionsTypes.SEARCH_USERS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.users = action.data;
      editedState.searchingUsers = false;
      break;

    case actionsTypes.SEARCH_USERS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.searchingUsers = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.SEARCHING_USER:
      editedState = Object.assign({}, state);
      editedState.searchingUser = true;
      break;

    case actionsTypes.SEARCH_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.selectedUser = action.data;
      editedState.searchingUser = false;
      break;

    case actionsTypes.SEARCH_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.searchingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.SAVING_USER:
      editedState = Object.assign({}, state);
      editedState.savingUser = true;
      break;

    case actionsTypes.SAVE_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.user = action.data;
      editedState.savingUser = false;
      break;

    case actionsTypes.SAVE_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.savingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.ENABLING_USER:
      editedState = Object.assign({}, state);
      editedState.enablingUser = true;
      break;

    case actionsTypes.ENABLE_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.enablingUser = false;
      break;

    case actionsTypes.ENABLE_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.enablingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;
 
    case actionsTypes.DISABLING_USER:
      editedState = Object.assign({}, state);
      editedState.disablingUser = true;
      break;

    case actionsTypes.DISABLE_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.disablingUser = false;
      break;

    case actionsTypes.DISABLE_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.disablingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.DELETING_USER:
      editedState = Object.assign({}, state);
      editedState.deletingUser = true;
      break;

    case actionsTypes.DELETE_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.deletingUser = false;
      break;

    case actionsTypes.DELETE_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.deletingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.CLEAN_SELECTED_USER:
      editedState = Object.assign({}, state);
      editedState.selectedUser = initialState.selectedUser;
      break;
 
    default:
      return state;
  }
  return editedState;
};
