
const assignRectoradoEmail = (params,message = 'El email debe pertenecer a Rectorado @utn.edu.ar o @rec.utn.edu.ar') => {
  const checkRectoradoEmail = (rule, value, callback) => {
    if (value && (value.trim().endsWith('@rec.utn.edu.ar') || value.trim().endsWith('@utn.edu.ar'))) {
      callback();
    } else {
      callback(message);
    }
  };
  return checkRectoradoEmail;
};

export default assignRectoradoEmail;