import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import secretarios from '../../secretarios';


const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};


class TramiteSoporteStep extends React.Component {



  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let tituloPeticion = 'Solicitud Soporte Técnico';
    let objsec= { nombre:'',secretaria:''};
    let objsecparent= { nombre:'',secretaria:'',email:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    let indice = secretarios.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsec = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,parent: secretarios[indice].parent };
    
     
    if(objsec.parent){
      let indice = secretarios.findIndex(elem => elem.id == objsec.parent);
      if(indice > -1)
        objsecparent = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,email: secretarios[indice].email };
    }

    descripcionPeticion += `\\n   -------- Datos del Responsable del Trámite --------`;
    if(objsecparent.email){
      descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
      descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      descripcionPeticion += `\\n  Nombre Autoridad: ` + objsecparent.nombre; 
    } else {
      descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
      descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
    }
    descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
    descripcionPeticion += `\\n  --------------- Datos del Trámite -----------------`;
    descripcionPeticion += `\\n  Nombre del Contacto: ${formData.nombreCuenta}`;
    descripcionPeticion += `\\n  Email del Contacto: ${formData.emailCuenta}`;
        
    if(formData.comentario)
      descripcionPeticion += ` \\n  Descripción de Solicitud: ${formData.comentario || '-'}`;

    const tramiteTipo = 'SOPORTE_TECNICO';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 10937,
        "subject": "${ tituloPeticion }",
        "description": "${ descripcionPeticion } ",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": 492,
        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
      }
    }`;

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    if(objsecparent.email)
      objetoenviar.emailparent = objsecparent.email;

    
    this.props.actions.saveTramite(objetoenviar,
      () => { this.props.actions.nextStep(); }
    );
    
    
    

  }

  getNombreInput = () => (
    <InputItem
      label="Nombre del Contacto"
      name="nombreCuenta"
      rules={ 
        [{
          required: true,
          message: 'El Nombre del Contacto es Obligatorio'
        }]
        
      }
    />
  )
  

  getEmailInput = () => (
    <InputItem
      label="Email del Contacto"
      name="emailCuenta"
      help="Es la cuenta de correo del contacto. La misma debe pertener al Dominio de Rectorado @utn.edu.ar @rec.utn.edu.ar"
      rules={ 
        [ {
          type: 'email',
          message: 'Debe ingresar un email valido' },
        {
          required: true,
          message: 'El Email del Agente es Obligatorio'
        },
        {
          type: 'CHECK_RECTORADO_EMAIL',
          message: 'El email debe pertenecer a Rectorado @utn.edu.ar o @rec.utn.edu.ar'
        }
        ]
        
      }
    />
  )
  
 

  getComentarioInput = () => (
    <TextAreaItem
      label="Descripción de Solicitud"
      name="comentario"
      rules={ [{ required: false }] }
    />
  )

  render() {

   

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form 
            onSave={ this.handleSave }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
          
            
          
            { this.getNombreInput() } 
              
            {  this.getEmailInput() }

            {  this.getComentarioInput() }
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de Soporte Técnico` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteSoporteStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteSoporteStep;
