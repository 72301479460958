import React from "react";
import PropTypes from "prop-types";
import { Layout, Row, Col } from "antd";
import GenericTable from "../../../../../shared/components/genericTable/GenericTable";
import GenericSearch from "../../../../../shared/components/genericSearch/GenericSearch";

const { Content } = Layout;

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    width: "20%"
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    width: "20%"
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "20%"
  },
  {
    title: "Estado",
    dataIndex: "habilitado",
    width: 300
  }
];

class Listado extends React.Component {
  state = {
    users: []
  };

  componentWillMount() {
    this.props.actions.searchUsers();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.users !== this.state.users) {
      this.setState({ users: this.props.users });
    }
  }

  handleDeleteClick = user => {
    this.props.actions.deleteUser(
      user.id,
      this.props.actions.searchUsers
    );
  };

  handleEnableDisableClick = user => {
    if (user.habilitado)
      this.props.actions.disableUser(
        user.id,
        this.props.actions.searchUsers
      );
    else
      this.props.actions.enableUser(
        user.id,
        this.props.actions.searchUsers
      );
  };

  handleSearch = users => {
    this.setState({ users: users });
  };

  render() {
    return (
      <React.Fragment>
        <Content style={ { margin: 24 } }>
          <Row gutter={ 16 }>
            <Col xs={ 24 } md={ 24 } lg={ 24 }>
              <GenericSearch
                backendModule={ "authentication" }
                backendEntity={ "usuario" }
                columnsWhereSearch={ ["nombre", "email"] }
                columnsToBeReturned={ columns }
                orderByColumn={ "id" }
                onSearch={ this.handleSearch }
              />
              <GenericTable
                columns={ columns }
                entities={ this.state.users }
                loading={ this.props.searchingUsers }
                newButtonUri={ "usuarios/new" }
                editButtonUri={ "usuarios/edit" }
                onDeleteRecord={ this.handleDeleteClick }
                onEnableDisableRecord={ this.handleEnableDisableClick }
                onChange={ this.handleSelectionChanged }
                debug={ true }
              />
            </Col>
          </Row>
        </Content>
      </React.Fragment>
    );
  }
}

Listado.propTypes = {
  searchingUsers: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    searchUsers: PropTypes.func,
    deleteUser: PropTypes.func,
    disableUser: PropTypes.func,
    enableUser: PropTypes.func
  }).isRequired
};

Listado.defaultProps = {
  searchingUsers: false,
  users: []
};

export default Listado;
