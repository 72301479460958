import React from 'react';
import PropTypes from 'prop-types';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import './LoginComponent.scss';


class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecoveringPassword: this.props.isRecoveringPassword
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRecoveringPassword !== this.props.isRecoveringPassword) {
      this.setState({isRecoveringPassword: this.props.isRecoveringPassword});
    }
  }

  render() {
    const showCard = () => {
      if (!this.state.isRecoveringPassword) {
        return (
          <div>
            <Login
              logInButtonText={ this.props.logInButtonText }
              userInputPlaceholder={ this.props.userInputPlaceholder }
              userInputErrorMessage={ this.props.userInputErrorMessage }
              passInputPlaceholder={ this.props.passInputPlaceholder }
              passInputErrorMessage={ this.props.passInputErrorMessage }
              loginErrorMessage={ this.props.loginErrorMessage }
              isWorking={ this.props.isWorking }
              handleLogin={ this.props.handleLogin }
              styles={ this.props.styles.login }
            />
            <a onClick={ event => { event.preventDefault(); this.setState({isRecoveringPassword: true}); } }>RECUPERAR CONTRASEÑA</a>
          </div>
        );
      }
      else {
        return (
          <div>
            <RecoverPassword
              recoverButtonText={ this.props.recoverButtonText }
              recoverInputPlaceholder={ this.props.recoverInputPlaceholder }
              recoverInputErrorMessage={ this.props.recoverInputErrorMessage }
              recoverErrorMessage={ this.props.recoverErrorMessage }
              isWorking={ this.props.isWorking }
              handleRecoverPassword={ this.props.handleRecoverPassword }
              recoverSuccessfulMessage={ this.props.recoverSuccessfulMessage }
              isRecoveringPasswordSuccessful={ this.props.isRecoveringPasswordSuccessful }
              styles={ this.props.styles.recoverPassword }
            />
            <a onClick={ event => { event.preventDefault(); this.setState({isRecoveringPassword: false}); } }>Regresar a la pantalla de inicio</a>
          </div>
        );
      }
      
    };

    return (
      <div className="mainWrapper" style={ this.props.styles.mainWrapper }>
        <div className="logoWrapper" style={ this.props.styles.logoWrapper }>
          { this.props.logo ?
            (<span><img src={ this.props.logo } className="logoImg" style={ this.props.styles.logoImg } /></span>) :
            null
          }
          <span>{ this.props.title }</span>
        </div>
        { showCard() }
      </div>
    );
  }
}

LoginComponent.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  logInButtonText: PropTypes.string.isRequired,
  userInputPlaceholder: PropTypes.string,
  userInputErrorMessage: PropTypes.string,
  passInputPlaceholder: PropTypes.string,
  passInputErrorMessage: PropTypes.string,
  loginErrorMessage: PropTypes.string.isRequired,
  recoverButtonText: PropTypes.string,
  recoverInputPlaceholder: PropTypes.string,
  recoverInputErrorMessage: PropTypes.string,
  recoverErrorMessage: PropTypes.string.isRequired,
  recoverSuccessfulMessage: PropTypes.string,
  styles: PropTypes.shape({
    mainWrapper: PropTypes.object,
    logoWrapper: PropTypes.object,
    logoImg: PropTypes.object,
    login: PropTypes.shape({
      loginErrorMessage: PropTypes.object
    }),
    recoverPassword: PropTypes.shape({
      recoverErrorMessage: PropTypes.object,
      recoverSuccessMessage: PropTypes.object
    })
  }),
  isWorking: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
  handleRecoverPassword: PropTypes.func.isRequired,
  isRecoveringPasswordSuccessful: PropTypes.bool
};

LoginComponent.defaultProps = {
  title: 'Bienvenido!',
  logo: null,
  logInButtonText: 'Entrar',
  userInputPlaceholder: 'Usuario',
  userInputErrorMessage: 'Por favor ingrese su nombre de usuario',
  passInputPlaceholder: 'Contraseña',
  passInputErrorMessage: 'No olvides la contraseña!',
  recoverButtonText: 'Recuperar contraseña',
  recoverInputPlaceholder: 'Usuario',
  recoverInputErrorMessage: 'Nombre de usuario inválido',
  recoverSuccessfulMessage: '¡Operación completada con éxito! Un correo con las instrucciones a seguir se ha enviado a su cuenta de correo.',
  styles: {},
  isWorking: false,
  isRecoveringPassword: false,
  isRecoveringPasswordSuccessful: false
};

export default LoginComponent;
