import React, { Component } from 'react';
import logoUtn from '../images/logo-utn.png';

import '../css/bootstrap.min.css'; // eslint-disable-line no-unused-vars
import '../css/default.css'; // eslint-disable-line no-unused-vars
import '../css/template.css'; // eslint-disable-line no-unused-vars

class HeaderUtn extends Component {

  render() {
    return (
      <header id="sp-header" style={ { height: 'auto', paddingTop: 25, paddingBottom: 25 } }>
        <div className="container">
          <div className="container-inner">
            <div className="row" style={ { display: 'flex', alignItems: 'center' } }>
              <div className="col-5 col-md-3 col-lg-3 col-sm-5 logo-desktop">
                <div className="sp-column"><a href="/"><img className="coming-soon-logo" src={ logoUtn } alt="Logo UTN " /></a>
                </div>
              </div>
              <div className="col-10 col-md-7 col-lg-7 col-sm-10 mobile-logo">
                <div className="sp-column"><a href="/index.php"><img src={ logoUtn } alt="Logo UTN " /></a>
                </div>
              </div>
              <div className="col-2 col-md-8 col-lg-8 col-xl-9 col-sm-2 logo-desktop" style={ { textAlign: 'center' } }>
                <div className="sp-column"><h3 className="header_title">UNIVERSIDAD TECNOLÓGICA NACIONAL</h3>
                </div>
              </div>
              <div style={ { display: 'flex' } } className="col-1 col-lg-1 col-xl-12">
                <div id="offcanvas-toggler" aria-label="Navigation" className="offcanvas-toggler-right d-block d-xl-none"><i className="fa fa-bars" aria-hidden="true" title="Navigation"></i>
                </div>
                <div id="sp-menu" style={ { fontFamily: 'Montserrat , arial' } }>
                  <div className="sp-column" style={ { position: 'relative' } }>
                    <div className="sp-module _menu" style={ { position: 'static' } }>
                      <div className="sp-module-content">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderUtn;