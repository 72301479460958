import decanos from '../../../../pages/tramites/decanos.json';
import secretarios from '../../../../pages/tramites/secretarios';

const assignIfIsDecanoRule = (params, message = 'El email debe pertenecer a un Decano o Autoridad de Rectorado.') => {
  const checkIfIsDecano = (rule, value, callback) => {
    decanos.map(representante => {
      if(representante.email === value){
        callback();    
      }
    });
    secretarios.map(representante => {
      if(representante.email === value){
        callback();    
      }
    });

    callback(message);
  };
  return checkIfIsDecano;
};

export default assignIfIsDecanoRule;