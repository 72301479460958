
const assignCheckMaVi = (params,message = 'La selección solo puede ser Martes o Jueves') => {
  const checkMaVi = (rule, value, callback) => {
    
    if( value && (value.day() == 2 || value.day() == 4 ))
    {
      callback();
    } else
    { 
      callback(message);
    }
    
   
  };
  return checkMaVi;
};

export default assignCheckMaVi;