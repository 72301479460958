import request from '../../shared/helpers/request';
import * as actionsTypes from './tramitesConstants';

export function setTramiteSeleccionado(tramite) {
  return { type: actionsTypes.SET_TRAMITE_SELECCIONADO, tramite };
} 

export function setSolicitante(solicitante) {
  return { type: actionsTypes.SET_SOLICITANTE, solicitante };
} 

export function setCurrentStep(stepID) {
  return { type: actionsTypes.SET_CURRENT_STEP, stepID };
} 

export function previousStep() {
  return { type: actionsTypes.SET_PREVIOUS_STEP };
} 

export function nextStep() {
  return { type: actionsTypes.SET_NEXT_STEP };
} 



export function savingTramite() {
  return { type: actionsTypes.SAVING_TRAMITE };
}

export function saveTramiteSuccess(data) {
  return { type: actionsTypes.SAVE_TRAMITE_SUCCESS, data };
}

export function saveTramiteFailure(errorMessage) {
  return { type: actionsTypes.SAVE_TRAMITE_FAILURE, errorMessage };
}

export function saveTramite(values, callbackSave = () => { }) {
  return function (dispatch) {
    dispatch(savingTramite());
    const errorCallback = error => {
      dispatch(saveTramiteFailure(error));
    };
    return request.post(`rutcore/tramites`,values, dispatch).then(
      response => {
        dispatch(saveTramiteSuccess(response.data));
        callbackSave();
      },
      errorCallback
    );
  };
}