import React from 'react';
import { Form, Icon, Input, Button } from 'antd';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class HorizontalLoginForm extends React.Component {
  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }

      if(this.props.buscarIssue) {
        this.props.buscarIssue(values.numeroissue);

      } else {

        this.props.history.push( '/buscartramite/' + values.numeroissue );
      }

      

  
    });
    
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const usernameError = isFieldTouched('username') && getFieldError('username');
    
    return (
      <Form layout="inline" onSubmit={ this.handleSubmit }>
        <Form.Item   validateStatus={ usernameError ? 'error' : '' } help={ usernameError || '' }>
          {getFieldDecorator('numeroissue', {
            rules: [{ required: true, message: 'Por favor ingrese un número de trámite' }],
          })(
            <Input
              size="20"
              type="number"
              prefix={ <Icon type="user" style={ { color: 'rgba(0,0,0,.25)' } } /> }
              placeholder="Número de trámite"
            />,
          )}
        </Form.Item>
     
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={ hasErrors(getFieldsError()) }>
            Buscar
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create({ name: 'horizontal_login' })(HorizontalLoginForm);

export default WrappedHorizontalLoginForm;