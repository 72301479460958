import React, { Fragment } from 'react';
import { Select, Card, Layout } from 'antd';
import BreadcrumbRoutes from '../layout/components/AppContent/Routes/BreadcrumbRoutes';

const { Content } = Layout;
const { Option } = Select;

const dependenciasList = [
  {
    label: 'Avellaneda',
    key: '1'
  },
  {
    label: 'Bahia Blanca',
    key: '2'
  },
  {
    label: 'Buenos Aires',
    key: '3'
  },
  {
    label: 'Chubut',
    key: '4'
  },
  {
    label: 'Concepcion del Uruguay',
    key: '5'
  },
  {
    label: 'Concordia',
    key: '6'
  },
  {
    label: 'Delta',
    key: '7'
  },
  {
    label: 'General Pacheco',
    key: '8'
  },
  {
    label: 'Haedo',
    key: '9'
  },
  {
    label: 'INSPT',
    key: '10'
  },
  {
    label: 'La Plata',
    key: '11'
  },
  {
    label: 'La Rioja',
    key: '12'
  },
  {
    label: 'Mar del Plata',
    key: '13'
  },
  {
    label: 'Mendoza',
    key: '14'
  },
  {
    label: 'Neuquen',
    key: '15'
  },
  {
    label: 'Parana',
    key: '16'
  },
  {
    label: 'Rafaela',
    key: '17'
  },
  {
    label: 'Reconquista',
    key: '18'
  },
  {
    label: 'Resistencia',
    key: '19'
  },
  {
    label: 'Tierra del Fuego',
    key: '20'
  },
  {
    label: 'Rosario',
    key: '21'
  },
  {
    label: 'San Fransisco',
    key: '22'
  },
  {
    label: 'San Nicolas',
    key: '23'
  },
  {
    label: 'San Rafael',
    key: '24'
  },
  {
    label: 'Santa Cruz',
    key: '25'
  },
  {
    label: 'Santa Fe',
    key: '26'
  },
  {
    label: 'Trenque Launquen',
    key: '27'
  },
  {
    label: 'Tucuman',
    key: '28'
  },
  {
    label: 'Venado Tuerto',
    key: '29'
  },
  {
    label: 'Villa Maria',
    key: '30'
  },
  {
    label: 'Rectorado',
    key: '31'
  }
];

class DependenciasPage extends React.Component {

  render() {
    return (
      <Fragment>
        
        <Content
          style={ { margin: '24px 16px' } }
        >
          <div style={ { marginBottom: 17 } }>
            <BreadcrumbRoutes />
          </div>
          <Card
            type="inner"
          >

            <Select
              labelInValue
              placeholder="Seleccione una Dependencia"
              filterOption={ false }
              style={ { width: '100%' } }
            >
              {
                dependenciasList.map(dependencia => (
                  <Option key={ dependencia.key }>{ dependencia.label }</Option>
                ))
              }
            </Select>
          </Card>
        </Content>
      </Fragment>
    );
  }
}

export default DependenciasPage;
 

 