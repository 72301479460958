import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Input, Button } from 'antd';


const FormItem = Form.Item;

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      isFormSent: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({isLoading: this.props.isWorking});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isWorking !== this.props.isWorking && !this.props.isWorking) {
      this.setState({isLoading: false});
    }
  }

  handleRecover = () => {
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({isLoading: true, isFormSent: true});
        this.props.handleRecoverPassword({ user: this.state.user });
        this.setState({isFormSent: true});
      }
      else {
        this.setState({isFormSent: false});
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const recoverErrorMessageVisibility = this.props.recoverErrorMessage
      && !this.state.isLoading
      && this.state.isFormSent ? 'block' : 'none';

    const showCard = () => {
      if (this.props.isRecoveringPasswordSuccessful && this.state.isFormSent) {
        return(
          <div>
            <div style={ { textAlign: 'center' } }>
              <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={ { fontSize: '24px' } } />
            </div>
            <p className="recoverSuccessMessage" style={ { ...this.props.styles.recoverSuccessMessage } }>
              { this.props.recoverSuccessfulMessage }
            </p>
          </div>
        );
      }
      else {
        return(
          <Form onSubmit = { event => { event.preventDefault(); this.handleRecover(); } }>
            <FormItem style ={ { paddingTop: 10 } }>
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: (this.props.recoverInputErrorMessage ? this.props.recoverInputErrorMessage : '') }]
              })(
                <Input
                  prefix={ <Icon type="user" style ={ { fontSize: 13 } } /> }
                  placeholder={ this.props.recoverInputPlaceholder ? this.props.recoverInputPlaceholder : '' }
                  onChange={ event => this.setState({user: event.target.value}) }
                />
              )}
            </FormItem>
            <FormItem>
              <Button
                type = "primary"
                htmlType = "submit"
                loading = { this.state.isLoading }
              >
                { this.props.recoverButtonText }
              </Button>
            </FormItem>
            <p className="recoverErrorMessage" style={ { display: recoverErrorMessageVisibility, ...this.props.styles.recoverErrorMessage } }>
              {this.props.recoverErrorMessage}
            </p>
          </Form>
        );
      }
    };

    return(
      <div>
        { showCard() }
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  recoverButtonText: PropTypes.string.isRequired,
  recoverInputPlaceholder: PropTypes.string.isRequired,
  recoverInputErrorMessage: PropTypes.string.isRequired,
  recoverErrorMessage: PropTypes.string.isRequired,
  isWorking: PropTypes.bool.isRequired,
  handleRecoverPassword: PropTypes.func.isRequired,
  recoverSuccessfulMessage: PropTypes.string.isRequired,
  isRecoveringPasswordSuccessful: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    recoverErrorMessage: PropTypes.object,
    recoverSuccessMessage: PropTypes.object
  })
};

RecoverPassword.defaultProps = {
  styles: {}
};

export default Form.create()(RecoverPassword);
