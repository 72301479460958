import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import listadoSedes from './listadosSedes';
import mergeCustomRules from '../../customRules';

const Option = Select.Option;
const AntFormItem = Form.Item;
const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};


class SedesUTN extends React.Component {

  constructor(props) {
    super(props);
    if(props.soloFacultades){
      var soloFacultades = listadoSedes.filter(elem => elem.key != 'REC' && elem.key != 'INSPT')
    }
    this.state = {
      loading: false,
      selectItems: soloFacultades || listadoSedes
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  renderSelectItems = () => {
    if (this.state.selectItems.length > 0){
      return this.state.selectItems.map(selectItem => 
        (
          <Option  key={ selectItem.nombre } value={ selectItem.nombre }>
            { selectItem.nombre }
          </Option>
        )
      );
    }else{
      return null;
    }
  }

  handleOnChange(value) {
    this.props.onItemChange(this.props.name, value);
  }
  
  render() {
    const { getFieldDecorator } = this.props.containerForm;
    let itemProps = Object.assign({}, this.props);

    delete itemProps.isFormEdition;
    delete itemProps.containerForm;
    delete itemProps.rules;
    delete itemProps.validateTrigger;
    delete itemProps.label;
    delete itemProps.name;
    delete itemProps.help;
    delete itemProps.hasFeedback;
    delete itemProps.initialValue;
    delete itemProps.genericSearchModule;
    delete itemProps.genericSearchColumnsToBeReturned;
    delete itemProps.genericSearchColumnsToBeOrderBy;
    delete itemProps.fieldsToShow;
    delete itemProps.staticData;
    delete itemProps.fieldsValues;
    delete itemProps.fieldsToShow;

    return (
      <AntFormItem
        style={ { marginBottom: 2 } }
        label= { this.props.label }
        help = { this.props.help }
        { ...this.props.formItemLayout }
      >
        {
          getFieldDecorator(
            this.props.name,
            {
              initialValue: this.props.initialValue,
              rules: mergeCustomRules(this.props.rules),
            }
          )(
            <Select
              onChange={ this.handleOnChange }
              { ...itemProps }
            >
              { this.renderSelectItems() }
            </Select>
          )
        }
      </AntFormItem>
    );
  }
}

SedesUTN.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.string,
  rules: PropTypes.array,
  formItemLayout: PropTypes.object,
  containerForm: PropTypes.object.isRequired,
  genericSearchModule: PropTypes.string,
  genericSearchModel: PropTypes.string,
  genericSearchColumnsToBeReturned: PropTypes.string,
  genericSearchColumnsToBeOrderBy: PropTypes.string,
  fieldsValues: PropTypes.array.isRequired,
  fieldsToShow: PropTypes.array.isRequired,
  dependsOnField: PropTypes.string,
  dependsOnFieldColumnMap: PropTypes.string,
  relatedFieldValue: PropTypes.string,
  onItemChange: PropTypes.func.isRequired,
};

SedesUTN.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  rules: [],
  formItemLayout: defaultFormItemLayout,
  dependsOnField: null,
  dependsOnFieldColumnMap: null,
  onItemChange: () => {
    console.warn("[SelectItem] onItemChange not implemented.");
  },
  relatedFieldValue: undefined
};


export default SedesUTN;
