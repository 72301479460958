import request from '../../../shared/helpers/request';
import * as actionsTypes from './parametrosConstants';

export function working() {
  return { type: actionsTypes.WORKING };
}

// --- Obtener Listado de Parametros ----
export function fetchParametrosSuccess(data) {
  return { type: actionsTypes.FETCH_PARAMETROS_SUCCESS, data };
}
export function fetchParametrosFailure(errorMessage) {
  return { type: actionsTypes.FETCH_PARAMETROS_FAILURE, errorMessage };
}
export function fetchParametros() {
  return function (dispatch) {
    dispatch(working());
    return request.get('application/configuracion', null, dispatch).then(
      response => {
        dispatch(fetchParametrosSuccess(response.data));
      }, 
      error => {
        dispatch(fetchParametrosFailure(error));
      }
    );
  };
}
// ---           End                 ----

// --- Guardar/Actualizar un Parametro ----
export function guardarParametroSuccess(data) {
  return { type: actionsTypes.SAVE_PARAMETROS_SUCCESS, data };
}
export function guardarParametroFailure(errorMessage) {
  return { type: actionsTypes.SAVE_PARAMETROS_FAILURE, errorMessage };
}
export function guardarParametro(parametro, callbackSave = ()=>{} ) {
  return function (dispatch) {
    dispatch(working());
    const errorCallback = error => {
      dispatch(guardarParametroFailure(error));
    };
    if(!parametro.id){
      return request.post('application/configuracion', parametro, dispatch).then(
        response => {
          dispatch(guardarParametroSuccess(response.data));
          callbackSave();
        },
        errorCallback
      );
    }else{
      return request
        .put(
          `application/configuracion/${parametro.id}`,
          parametro,
          dispatch
        )
        .then(response => {
          dispatch(guardarParametroSuccess(response.data));
          callbackSave();
        }, errorCallback);
    }
  };
}
// ---           End                 ----

// ---     Eliminar un Parametro     ----
export function eliminarParametroSuccess(data) {
  return { type: actionsTypes.DELETE_PARAMETROS_SUCCESS, data };
}
export function eliminarParametroFailure(errorMessage) {
  return { type: actionsTypes.DELETE_PARAMETROS_FAILURE, errorMessage };
}
export function eliminarParametro(id, callbackDelete = ()=>{} ) {
  return function (dispatch) {
    dispatch(working());
    return request.delete(`application/configuracion/${id}`, null, dispatch).then(
      response => {
        dispatch(eliminarParametroSuccess(response.data));
        callbackDelete();
      },
      error =>{
        dispatch(eliminarParametroFailure(error));
      }
    );
  };
}
// ---           End                 ----

// --- Obtener UN Parametro ----
export function fetchParametroSuccess(data) {
  return { type: actionsTypes.FETCH_PARAMETRO_SUCCESS, data };
}
export function fetchParametroFailure(errorMessage) {
  return { type: actionsTypes.FETCH_PARAMETRO_FAILURE, errorMessage };
}
export function fetchParametro(id) {
  return function (dispatch) {
    dispatch(working());
    return request.get(`application/configuracion/${id}`, null, dispatch).then(
      response => {
        dispatch(fetchParametroSuccess(response.data));
      }, 
      error => {
        dispatch(fetchParametroFailure(error));
      }
    );
  };
}
// ---           End                 ----
 
export function limpiarParametroSeleccionado() {
  return { type: actionsTypes.CLEAN_SELECTED };
}