import React from 'react';
import PropTypes from 'prop-types';
import { Form, TimePicker } from 'antd';
import mergeCustomRules from '../customRules';

class TimeItem extends React.Component {
  constructor(props) {
    super(props);
   

  }
  newOnchange = (event,value) => {
    const { validateFields } = this.props.containerForm;
    validateFields([this.props.duracion]);  
    // setFieldsValue({[inputProps.duracion]:''});  
    this.props.onChange(event,value) 
  }
  

  
  render() {
    const format = 'HH:mm';
    const { getFieldDecorator} = this.props.containerForm;  
    let inputProps = Object.assign({}, this.props);
    
    delete inputProps.isFormEdition;
    delete inputProps.containerForm;
    delete inputProps.rules;
    delete inputProps.label;
    delete inputProps.name;
    delete inputProps.help;

    if(inputProps.duracion) 
    {
      inputProps.onChange = this.newOnchange;  
    }


    return (
      <div >
        <Form.Item
          style={ { marginBottom: 2 } }
          label= { this.props.label }
          help = { this.props.help }
          { ...this.props.formItemLayout }
        >
          {
            getFieldDecorator(
              this.props.name,
              {
                rules: mergeCustomRules(this.props.rules)
               
               
              }
            )(
              <TimePicker  format={ format } { ...inputProps } />
            )
          }
        </Form.Item>
      </div>

    );
  }
}

TimeItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object.isRequired,
  formItemLayout: PropTypes.object
 
};

const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

TimeItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  placeholder: '',
  rules: [{ type: 'object', required: false, message: 'La fecha es obligatoria.' }],
  formItemProps: {},
  itemProps: {},
  formItemLayout: defaultFormItemLayout 

};

export default TimeItem;