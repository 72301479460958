import React from 'react';
import { Layout, Card,Row, Col,Spin,Button  } from 'antd';
import PropTypes from 'prop-types';

import request from '../../shared/helpers/request';
import BuscarComponent from '../home/BuscarComponent';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tramitesActions from '../../redux/tramites/tramitesActions';





const { Content } = Layout;

class BuscarTramite extends React.Component {
  state = {
    issue: null,
    numero:null,
    notFound:false
  };

  handleChangeHoras = evt =>
    this.setState({ horas: evt.target.value });

  getEpochTime = () => {
    const epochTime = (new Date).getTime();
    return epochTime.toString().substring(0, 10);
  }

  getTramite = numero => {
    this.setState({
      issue: null,
      numero: null,
      notFound:false

    })
    request.get('rutcore/tramites/' + numero )
      .then(response => {
        
        this.setState({
          issue: response.data,
          numero: numero,
          notFound:false
        })
      },() => {

        this.setState({
          issue: null,
          numero: numero,
          notFound:true
        })

      })

  }

  componentDidMount() {
   
    this.getTramite(this.props.match.params.issue);


  }

  buscarIssue = numero => {
    this.props.history.replace('/buscartramite/' + numero);
    this.getTramite(numero);

  }

  render() {
    return (
      <Content style={ { margin: '0px 0px' } }>
        
      
       
        <Card
       
          type="inner"
          title={ "Información de Trámite" }
          style={ { background: '#fff' } }
        >
          <Button style={ { marginLeft: 15,marginTop:15 } } onClick={ () => {
            this.props.history.push("/")
               
          } }>
                    Volver
          </Button>
          <Row gutter={ 16 }  style={ { textAlign: 'center',paddingTop:7 } } >
           
            <Col lg={ 24 } md={ 24 } xs={ 24 }>
        
              { this.state.numero ? <h5>Trámite Número { this.state.numero } </h5> : <Spin size="large" /> }
              { this.state.notFound && <h6 style={ { color:'red' } }>El número de trámite ingresado no se encuentra en nuestra base</h6> }
              { this.state.issue && !this.state.issue.id && <h6 style={ { color:'red' } }>El número ingresado no corresponde a un trámite autorizado para visualizar el estado</h6> }
              { this.state.issue && this.state.issue.id &&
            <div>
              <p>
                Estado: <span style={ { fontWeight:'bold'} } >{ this.state.issue.estado }{ this.state.issue.fecha_cierre && ( " (" + moment(this.state.issue.fecha_cierre).format('DD-MM-YYYY') + ")" ) }</span>
              </p>
              <p>   
                Título: <span style={ { fontWeight:'bold'} } >{ this.state.issue.titulo }</span>
              </p>
              <p>
    Fecha de Inicio: <span style={ { fontWeight:'bold'} } >{ moment(this.state.issue.fecha_inicio).format('DD-MM-YYYY') }</span>
              </p>
              <p>
                Fecha estimada de cierre: <span style={ { fontWeight:'bold'} } >{ moment(this.state.issue.fecha_vencimiento).format('DD-MM-YYYY') }</span>
              </p>
              
            </div>
        
              
              }
              
            
            </Col>
          
          </Row>
          


        </Card>

        <Row gutter={ 24 } >
           
          <Col lg={ 24 } md={ 24 } xs={ 24 }>
            <Card title="Buscar Trámite" style={ { marginTop: 20, marginBottom: 20, height: '100%'} }>
              <Row gutter={ 16 } >
                <Col lg={ 24 } md={ 24 } xs={ 24 }>
                 
                  <BuscarComponent buscarIssue={ numero => this.buscarIssue(numero) }  { ...this.props } />

                </Col>
              </Row>

            </Card>
          </Col>
        </Row>
      

      </Content>
    );
  }
}

BuscarTramite.propTypes = {
  trabajando: PropTypes.bool
};


function mapStateToProps(state) {
  return {
    currentStep: state.tramites.currentStep,
    tramiteSeleccionado: state.tramites.tramiteSeleccionado, 
    solicitante: state.tramites.solicitante,
    codigoTramiteGenerado: state.tramites.codigoTramiteGenerado,
    savingTramite: state.tramites.savingTramite
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(tramitesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuscarTramite);
