import * as actionsTypes from './appConstants';

const initialState = {
  errorMessage: '',
  validatingFieldExist: false,
  fieldExist: false
};
const usableState = Object.assign({}, initialState);

export default function appReducer(state = usableState, action) {
  let editedState;
  switch (action.type) {

    case actionsTypes.VALIDATING_FIELD_EXIST:
      editedState = Object.assign({}, state);
      editedState.validatingFieldExist = true;
      break;

    case actionsTypes.VALIDATE_FIELD_EXIST_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.validatingFieldExist = false;
      editedState.fieldExist = action.data.exist;
      break;

    case actionsTypes.VALIDATE_FIELD_EXIST_FAILURE:
      editedState = Object.assign({}, state);
      editedState.validatingFieldExist = false;
      editedState.errorMessage = action.errorMessage;
      break;

    default:
      return state;
  }
  return editedState;
}
