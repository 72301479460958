import secretarios from '../../../../pages/tramites/secretarios';

const assignIfIsSecretarioRule = (params, message = 'El email debe pertenecer a un secretario de Rectorado.') => {
  const checkIfIsSecretario = (rule, value, callback) => {
    secretarios.map(secretario => {
      if(secretario.email === value.trim()){
        callback();    
      }
    });
    callback(message);
  };
  return checkIfIsSecretario;
};

export default assignIfIsSecretarioRule;