import React, { Fragment } from 'react';
import { Card, PageHeader, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import FormItem from 'antd/lib/form/FormItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import secretarios from '../../secretarios';

const Option = Select.Option;

const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};


class TramiteCorreoElectronicoStep extends React.Component {

  state = {
    accion: 'ALTA_PERSONAL'
  }

  

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let tituloPeticion = '';
    let objsec= { nombre:'',secretaria:''};
    let objsecparent= { nombre:'',secretaria:'',email:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    let indice = secretarios.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsec = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,parent: secretarios[indice].parent };
    
     
    if(objsec.parent){
      let indice = secretarios.findIndex(elem => elem.id == objsec.parent);
      if(indice > -1)
        objsecparent = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,email: secretarios[indice].email };
    }


    if (this.state.accion === 'ALTA_PERSONAL') {
      tituloPeticion = 'Creación de usuario VPN Rectorado';


      descripcionPeticion += `\\n   -------- Datos del Responsable del Trámite --------`;
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
        descripcionPeticion += `\\n  Nombre Autoridad: ` + objsecparent.nombre; 
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n  --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n  Nombre del Agente: ${formData.nombreCuenta}`;
      descripcionPeticion += `\\n  Apellido del Agente: ${formData.apellidoCuenta}`;
      descripcionPeticion += `\\n  Email del Agente: ${formData.emailCuenta || '-'}`;
      descripcionPeticion += `\\n  Legajo del Agente: ${formData.legajo || '-'}`;
    }


    if (this.state.accion === 'SUSPENSION_PERSONAL') {
      tituloPeticion = 'Baja de usuario de VPN';
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      }
      descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      descripcionPeticion += `\\n --------------- Datos del Trámite -----------------`;
      descripcionPeticion += `\\n Nombre del Agente: ${ formData.nombreCuenta || '-'}`;
      descripcionPeticion += `\\n Apellido del Agente: ${ formData.apellidoCuenta || '-'}`;
      
     
    }

    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;

    if(formData.motivo)
      descripcionPeticion += ` \\n   Motivo: ${formData.motivo || '-'}`;

    const tramiteTipo = 'GESTION_VPN';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 10941,
        "subject": "${ tituloPeticion }",
        "description": "${ descripcionPeticion } ",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": 327,

        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ],
        "watcher_user_ids":[330,320]
      }
    }`;

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    if(objsecparent.email)
      objetoenviar.emailparent = objsecparent.email;

    
    this.props.actions.saveTramite(objetoenviar,
      () => { this.props.actions.nextStep(); }
    );
    
    
    

  }

  getNombreInput = () => (
    <InputItem
      label="Nombre del Agente"
      name="nombreCuenta"
      rules={ 
        [{
          required: true,
          message: 'El Nombre del Agente es Obligatorio'
        }]
        
      }
    />
  )
  
  getApellidoInput = () => (
    <InputItem
      label="Apellido del Agente"
      name="apellidoCuenta"
      rules={ 
        [{
          required: true,
          message: 'El Nombre del Agente es Obligatorio'
        }]
        
      }
    />
  )

  getEmailInput = () => (
    <InputItem
      label="Email del Agente"
      name="emailCuenta"
      help="Es la cuenta de correo en donde se enviaran las credenciales de acceso. La misma debe pertener al Dominio de Rectorado @utn.edu.ar @rec.utn.edu.ar"
      rules={ 
        [ {
          type: 'email',
          message: 'Debe ingresar un email valido' },
        {
          required: true,
          message: 'El Email del Agente es Obligatorio'
        },
        {
          type: 'CHECK_RECTORADO_EMAIL',
          message: 'El email debe pertenecer a Rectorado @utn.edu.ar o @rec.utn.edu.ar'
        }
        ]
        
      }
    />
  )
  
  getLegajoInput = () => (
    <InputItem
      label="Legajo del agente"
      name="legajo"
    />
  )





  getMotivoInput = () => (
    <TextAreaItem
      label="Motivo"
      name="motivo"
      rules={ [{ required: false }] }
    />
  )

  



  getComentarioInput = () => (
    <TextAreaItem
      label="Comentario"
      name="comentario"
      rules={ [{ required: false }] }
    />
  )

  render() {
    const { accion } = this.state;

    const defaultFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form 
            onSave={ this.handleSave }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
            <FormItem
              style={ { marginBottom: 2 } }
              label="Tipo de Accion"
              { ...defaultFormItemLayout }
            >
              <Select
                defaultValue={ accion }
                onChange={ value => { this.setState({ accion: value }); } }
              >
                <Option value="ALTA_PERSONAL">Alta Usuario</Option>
                <Option value="SUSPENSION_PERSONAL">Baja Usuario</Option>
              </Select>
            </FormItem>
            
            { accion == 'ALTA_PERSONAL' || accion == 'SUSPENSION_PERSONAL'
              ? this.getNombreInput(accion) 
              : <span />
            }
            { accion == 'ALTA_PERSONAL' || accion == 'SUSPENSION_PERSONAL'
              ? this.getApellidoInput(accion) 
              : <span />
            }
            { accion == 'ALTA_PERSONAL'
              ? this.getEmailInput()
              : <span />
            }

            { accion == 'ALTA_PERSONAL'
              ? this.getLegajoInput()
              : <span />
            }
          
            {  accion == 'SUSPENSION_PERSONAL'
              ? this.getMotivoInput() 
              : <span />
            }

            {  accion == 'ALTA_PERSONAL'
              ? this.getComentarioInput() 
              : <span />
            }
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de VPN` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteCorreoElectronicoStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteCorreoElectronicoStep;
