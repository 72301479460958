
const assignCheckFinde = (params,message = 'El rango de la fecha es de Lunes a Viernes') => {
  const checkFinde = (rule, value, callback) => {
    
    if( value && (value.day() == 6 || value.day() == 0 ))
    {
      callback(message);
    } else
    { 
      callback();
    }
    
   
  };
  return checkFinde;
};

export default assignCheckFinde;