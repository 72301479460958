import request from '../../shared/helpers/request';
import * as actionsTypes from './appConstants';

export function validatingFieldExist() {
  return { type: actionsTypes.VALIDATING_FIELD_EXIST };
}

export function validatingFieldExistSuccess(data) {
  return { type: actionsTypes.VALIDATE_FIELD_EXIST_SUCCESS, data };
}

export function validatingFieldExistFailure(errorMessage) {
  return { type: actionsTypes.VALIDATE_FIELD_EXIST_FAILURE, errorMessage };
}

export function validateFieldExist(model, field, value) {
  return function (dispatch) {
    dispatch(validatingFieldExist());
    return request.get(`application/exist?module=application&model=${model}&column=${field}&value=${value}`, null, dispatch).then(
      response => {
        dispatch(validatingFieldExistSuccess(response.data));
      }, 
      error => {
        let message;
        try {
          message = error.data.errMsg ? error.data.errMsg : 'Hubo un error...';
        } catch (e) {
          message = 'Hubo un error...';
        }
        dispatch(validatingFieldExistFailure(message));
        throw (error);
      }
    );
  };
}
 