import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TramitesPage from './TramitesPage';
import * as tramitesActions from '../../redux/tramites/tramitesActions';

function mapStateToProps(state) {
  return {
    currentStep: state.tramites.currentStep,
    tramiteSeleccionado: state.tramites.tramiteSeleccionado, 
    solicitante: state.tramites.solicitante,
    codigoTramiteGenerado: state.tramites.codigoTramiteGenerado,
    savingTramite: state.tramites.savingTramite
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(tramitesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TramitesPage);
