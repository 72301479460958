import React, { Fragment } from 'react';
import { Card, Icon, Row, Col  } from 'antd';
import PropTypes from 'prop-types';

const { Meta } = Card;

const gridStyle = {
  width: '32%',
  textAlign: 'center' 
};

class TipoSolicitudesStep extends React.Component {
  state = {
    tramiteSeleccionado: "NINGUNO"
  };

  seleccionarTramite = config  =>{
    // this.setState({ tramiteSeleccionado: config.title});
    //let title = config.title;
    //if (config.title instanceof Object)
    // title = config.title.props.children;

    //this.props.actions.setTramiteSeleccionado({ tramiteId: config.tramiteId, title: title});
    
    this.props.history.push("/tramites/" + config.tramiteId )
  }
  
  renderIcon = iconName => 
    (<Icon type={ iconName }
      style={ { fontSize: '25px', color: '#1890ff' } }
    />) 

  renderTramiteCard = config => 
    (
      <Card.Grid style={ gridStyle } onClick={ () => { this.seleccionarTramite(config); } }>
        <Card
          cover={ this.renderIcon(config.icon) }
          bordered={ false }
        >
          <Meta title={ config.title } description={ config.description }  />
        </Card>
      </Card.Grid>
    );

  renderCard = config => 
    (
      <Card
        style={ { textAlign: 'center',paddingTop:5 } }
        cover={ this.renderIcon(config.icon) }
        bordered={ true }
        hoverable={ true }
        onClick={ () => { this.seleccionarTramite(config); } }
      >
        <Meta title={ config.title } description={ config.description }  />
      </Card>
    );
  
     
  render() {
    return (
      <Fragment>
       
        <Card title="Trámites para Autoridades" style={ { marginTop: 0, marginBottom: 0, height: '100%'} }>
          <Row gutter={ 24 } style={ { marginBottom: 15 } }>
            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:2,
                  icon: 'mail', 
                  title: < div style = { { color: 'red' } }>Correo Electrónico Rectorado</div>,
                  description:'Alta, Modificación, Suspensión',
                  requiereEmailSecretario: true
                })
              }
            </Col>
            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:8,
                  icon: 'apartment', 
                  title:< div style = { { color: 'red' } }>VPN Rectorado</div>,
                  description:'Alta y Baja de usuario'
                })
              }
            </Col>
            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:5,
                  icon: 'phone', 
                  title: < div style = { { color: 'red' } }>Telefonía Rectorado</div>,
                  description:'Nuevos o por Mudanza y Llamadas'
                })
              }
            </Col>
          
          </Row> 
        
          <Row type="flex" justify="center" gutter={ 24 } style={ { marginBottom: 0 } }>
            <Col  lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:3,
                  icon: 'edit', 
                  title:< div style = { { color: 'red' } }>Firma Digital</div>,
                  description:'Solicitud turno para carga'
                })
              }
            </Col>
            <Col lg={ 8 } md={ 24 } xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:10,
                  icon: 'laptop', 
                  title:< div style = { { color: 'red' } }>Soporte</div>,
                  description:'Soporte técnico Rectorado'
                })
              }
            </Col>
          </Row>
    
           
           
         
         
        
        
        </Card>
        <Card title="Trámites Generales" style={ { marginTop: 0, marginBottom: 0, height: '100%'} }>
          <Row gutter={ 24 } style={ { marginBottom: 0 } }>

            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({
                  tramiteId: 6,
                  icon: 'calendar',
                  title: 'Licencias de Software',
                  description: 'Antivirus, Microsoft, Zoom, Otras'
                })
              }
            </Col>
            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:7,
                  icon: 'fire', 
                  title:'Incidentes',
                  description:'Problemas con Campus Virtual, RIA, Bibliotecas, Red Interna, Otros'
                })
              }
            </Col>
            { /*
            <Col lg={ 6 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:1,
                  icon: 'video-camera', 
                  title: 'Video Conferencia',
                  description:'Asistencia'
                })
              }
            </Col>
            */ }
            <Col lg={ 8 } md={ 24 }xs={ 24 }>
              {
                this.renderCard({ 
                  tramiteId:9,
                  icon: 'desktop', 
                  title: 'SIED',
                  description:'Sistema Institucional de Educación a Distancia'
                })
              }
            </Col>
           
          
           
         
          </Row> 
        
        </Card>

      </Fragment>
    );
  }
}

TipoSolicitudesStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TipoSolicitudesStep;
