export const WORKING = 'WORKING';
export const CLEAN_SELECTED = 'CLEAN_SELECTED';
export const FETCH_PARAMETROS_SUCCESS = 'FETCH_PARAMETROS_SUCCESS';
export const FETCH_PARAMETROS_FAILURE = 'FETCH_PARAMETROS_FAILURE';
export const SAVE_PARAMETROS_SUCCESS = 'SAVE_PARAMETROS_SUCCESS';
export const SAVE_PARAMETROS_FAILURE = 'SAVE_PARAMETROS_FAILURE';
export const DELETE_PARAMETROS_SUCCESS = 'DELETE_PARAMETROS_SUCCESS';
export const DELETE_PARAMETROS_FAILURE = 'DELETE_PARAMETROS_FAILURE';
export const FETCH_PARAMETRO_SUCCESS = 'FETCH_PARAMETRO_SUCCESS';
export const FETCH_PARAMETRO_FAILURE = 'FETCH_PARAMETRO_FAILURE';
