import { combineReducers } from 'redux';

import app from './app/appReducer';
import login from './auth/authReducer';
import sistemaParametros from './sistema/parametros/parametrosReducer';
import usuarios from './sistema/usuarios/usuariosReducer';
import tramites from './tramites/tramitesReducer';

const rootReducer = combineReducers({
  app,
  login,
  sistemaParametros,
  usuarios,
  tramites
});

export default rootReducer;
