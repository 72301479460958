/* eslint-disable import/default */

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './app';
import './index.scss';

import { LocaleProvider } from 'antd';
import es_ES  from 'antd/lib/locale-provider/es_ES';
import moment from 'moment';
import 'moment/locale/es';

require('./favicon.ico'); // Tell webpack to load favicon.ico
moment.locale('es');

ReactDOM.render(
  <LocaleProvider locale={ es_ES }><App /></LocaleProvider>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./app', () => {
    ReactDOM.render(
      <AppContainer>
        <App />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}