import React from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm, Card as AntCard, Button as AntButton } from "antd";


class Form extends React.Component {

  constructor(props) {
    super(props);
    
    let saveButtonText = '';
    if(this.props.saveButtonText){
      saveButtonText = this.props.saveButtonText;
    } else if (this.props.isFormEdition){
      saveButtonText = 'Actualizar';
    }else{
      saveButtonText = 'Guardar';
    }

    this.state = {
      saveButtonDisable: true,
      saveButtonText: saveButtonText
    };
  }

  componentWillUpdate(nextProps) {

    if (this.props.formValues && (this.props.formValues.id !== nextProps.formValues.id)) {
      if (nextProps.formValues.id) {
        if (!this.props.saveButtonText)
          this.setState({ saveButtonText: 'Actualizar' });
        let formValues = Object.assign({}, nextProps.formValues);
        this.props.form.setFieldsValue(formValues);
      } else {
        if (!this.props.saveButtonText)
          this.setState({ saveButtonText: 'Guardar' });
      }
    }

    if (this.props.form.isFieldsTouched()) {
      const errorsObj = this.props.form.getFieldsError();
      let disableSaveButton = false;
      for (let property in errorsObj) {
        if (errorsObj[property]) {
          disableSaveButton = true;
          break;
        }
      }
      if (disableSaveButton !== this.state.saveButtonDisable) {
        this.setState({ saveButtonDisable: disableSaveButton });
      }
    }
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll({ force: true },(errors, values) => {
      if (!errors) {
        this.props.onSave(values);
      } else {
        this.props.handleErrors(errors);
      }
    });
  }

  render() {
    const childrenInjectedWithFormProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        isFormEdition: this.props.isFormEdition,
        containerForm: this.props.form
      })
    );

    return (
      <AntCard bordered={ false }>
        <AntForm onSubmit={ event => { event.preventDefault(); this.handleSubmit(); } }>
          {childrenInjectedWithFormProps}
          {
            this.props.showSaveButton &&
            <AntForm.Item
              formItemLayout={ {
                wrapperCol: {
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }
              } }
            >
              <AntButton
                type="primary"
                htmlType="submit"
                /*disabled={ this.state.saveButtonDisable } */
                loading={ this.props.saving }
              >
                {this.state.saveButtonText}
              </AntButton>

            </AntForm.Item>
          }
        </AntForm>
      </AntCard>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  handleErrors: PropTypes.func,
  showSaveButton: PropTypes.bool,
  isFormEdition: PropTypes.bool,
  saveButtonText: PropTypes.string,
  saving: PropTypes.bool

};

Form.defaultProps = {
  formValues: {},
  handleErrors: () => { },
  showSaveButton: true,
  saving: false,
  isFormEdition: false
};

export default AntForm.create()(Form);
