import React, { Fragment } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import Form from '../../../../../shared/components/genericForm/Form';
import InputItem from '../../../../../shared/components/genericForm/formItems/InputItem';
import SelectItem from '../../../../../shared/components/genericForm/formItems/SelectItem';

const { Content } = Layout;

class Formulario extends React.Component {
  
  constructor(){
    super();
  }

  componentWillMount() {
    this.props.actions.cleanSelectedUser();
    if (this.isEdition()) {
      this.props.actions.searchUser( this.props.match.params.id );
    }
  }

  isEdition = () => {
    const { id } = this.props.match.params;
    if (id) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = usuario => {
    if (this.isEdition()) {
      usuario.id = this.props.match.params.id;
      delete usuario.password;
    }
    this.props.actions.saveUser( usuario, ()=>{ alert('user saved'); } );
  };

  render() {
    let titulo = this.isEdition() ? "Editar Usuario" : "Nuevo Usuario";
    return (
      <Fragment>
        <Content> 
          <Form 
            title={ titulo }
            onSave={ this.handleSave }
            formValues={ this.props.selectedUser }
            isFormEdition={ this.isEdition() }
          >
            <InputItem
              label="nombre"
              name="nombre"
              rules={ [{ required: true, message: 'El nombre de usuario es requerido' }] }
            />
            <SelectItem
              label="Rol"
              name="role"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label',' [','value',']'] }
              staticData={ [
                {label:'Super Administrador',value:'SUPER_ADMNISTRADOR' },
                {label:'Administrador',value:'ADMINISTRADOR' },
                {label:'Usuario',value:'USUARIO' }
              ] }
              rules={ [{ required: true, message: 'El rol de usuario es requerido' }] }
            />
            <InputItem 
              label="E-Mail" 
              name="email"
              rules={ [
                { required: true, message: 'El email del usuario es requerido' },
                { type: 'email', message: 'Se requiere un email valido.' }
              ] } 
               
            />
            <InputItem 
              label="Password" 
              name="password" 
              hideText={ true }
            />
          </Form>
        </Content>
      </Fragment>
    );
  }
}

Formulario.propTypes = {
  trabajando: PropTypes.bool,
  dadorCargaSelected: PropTypes.object,
  selectedUser: PropTypes.object,
  actions: PropTypes.shape({
    saveUser: PropTypes.func
  }).isRequired

};

export default Formulario;
