import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker } from 'antd';
import mergeCustomRules from '../customRules';
import moment from 'moment';


class DateItem extends React.Component {

  disabledDate = current =>  current && current < moment(new Date()).add(-1, 'days');
  

  
  render() {
    const { getFieldDecorator } = this.props.containerForm;
    let inputProps = Object.assign({}, this.props);
    delete inputProps.isFormEdition;
    delete inputProps.containerForm;
    delete inputProps.rules;
    delete inputProps.label;
    delete inputProps.name;
    delete inputProps.help;
    return (
      <div >
        <Form.Item
          style={ { marginBottom: 2 } }
          label= { this.props.label }
          help = { this.props.help }
          { ...this.props.formItemLayout }
        >
          {
            getFieldDecorator(
              this.props.name,
              {
                rules: mergeCustomRules(this.props.rules),
              }
            )(
              <DatePicker format="DD-MM-YYYY" disabledDate={ this.disabledDate } { ...inputProps }/>
            )
          }
        </Form.Item>
      </div>

    );
  }
}

DateItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object.isRequired,
  formItemLayout: PropTypes.object
 
};


const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

DateItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  placeholder: '',
  rules: [{ type: 'object', required: false, message: 'La fecha es obligatoria.' }],
  formItemProps: {},
  itemProps: {},
  formItemLayout: defaultFormItemLayout 

};

export default DateItem;