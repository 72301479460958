
const assignCheckUTNDomainInMail = (message = 'El email debe pertenecer al dominio utn.edu.ar') => {
  const checkUTNDomainInMail = (rule, value, callback) => {
    let domainRegex = RegExp(/^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(utn)\.edu.ar$/, 'g');
    console.log("paso por aca");
    console.log(value);
    
    if (value && domainRegex.test(value.trim())) {
      callback();
    } else {
      callback(message);
    }
  };
  return checkUTNDomainInMail;
};

export default assignCheckUTNDomainInMail;