import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import mergeCustomRules from '../customRules';

const AntFormItem = Form.Item;

const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

class CheckBoxItem extends React.Component {

  render() {
    const { getFieldDecorator } = this.props.containerForm;
    let inputProps = Object.assign({}, this.props);
    delete inputProps.isFormEdition;
    delete inputProps.containerForm;
    delete inputProps.rules;
    delete inputProps.label;
    delete inputProps.name;
    delete inputProps.help;
    return (
      <AntFormItem
        style={ { marginBottom: 2 } }
        label= { this.props.label }
        help = { this.props.help }
        { ...this.props.formItemLayout }
      >
        {
          getFieldDecorator(
            this.props.name,
            {
              rules: mergeCustomRules(this.props.rules),
            }
          )(
            <Checkbox { ...inputProps }/>
          )
        }
      </AntFormItem>
    );
  }
}

CheckBoxItem.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.array,
  formItemLayout: PropTypes.object,
  formInstance: PropTypes.object.isRequired
};

CheckBoxItem.defaultProps = {
  name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
  rules: [],
  formItemLayout: defaultFormItemLayout 
};


export default CheckBoxItem;
