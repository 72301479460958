import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import Form from '../../../../shared/components/genericForm/Form';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import DateItem from '../../../../shared/components/genericForm/formItems/DateItem';
import secretariasJson from '../../secretarias.json';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
//import secretariasJson from '../../secretarias.json';
import representantes from '../../representantes.json';
import secretarios from '../../secretarios.json';
import autorizadosLicencias from '../../autorizadosLicencias.json';



const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteSolicitudLicenciasSoftwareStep extends React.Component {
  state = {
    renderLicencias: <span />,
    showSaveButton : true

  }

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let assigned = '490';
    let watchers = null;
    if(formData.tipoLicencia == 'zoom' || formData.tipoLicencia == 'webinar') {
      assigned = '332';
      watchers = ',"watcher_user_ids":[325]';
    }
    if(formData.tipoLicencia == 'antivirus' || formData.tipoLicencia == 'microsoft') {
      assigned = '490';
      watchers = ',"watcher_user_ids":[478]';
    }
      

    let tituloPeticion = '';
    let objrep= { nombre:'',dependencia:''};
    let objauth= { nombre:'',dependencia:''};
    let objsec= { nombre:'',secretaria:''};
    let objsecparent= { nombre:'',secretaria:'',email:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

   

    let indice = secretarios.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsec = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,parent: secretarios[indice].parent };
    
    let indice_rep = representantes.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice_rep > -1)
      objrep = { nombre:representantes[indice_rep].nombre, dependencia:representantes[indice_rep].dependencia };

    let indice_auth = autorizadosLicencias.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice_auth > -1)
      objauth = { nombre:autorizadosLicencias[indice_auth].nombre, dependencia:autorizadosLicencias[indice_auth].dependencia };  
        
    
     
    if(objsec.parent){
      let indice = secretarios.findIndex(elem => elem.id == objsec.parent);
      if(indice > -1)
        objsecparent = { nombre:secretarios[indice].nombre, secretaria:secretarios[indice].secretaria,email: secretarios[indice].email };
    }


    tituloPeticion = 'Solicitud de Licencias de ' + formData.tipoLicencia + ' - ' + (objrep.dependencia ? objrep.dependencia : 'Rectorado');
    descripcionPeticion += `\\n   -------- Datos del Responsable del Tramite --------`;
    if(objsec.nombre) {
      if(objsecparent.email){
        descripcionPeticion += `\\n  Nombre del Autorizado: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email del Autorizado: ${this.props.solicitante.email}`;
        descripcionPeticion += `\\n  Nombre Autoridad: ` + objsecparent.nombre; 
        descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      } else {
        descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsec.nombre}`;
        descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
        descripcionPeticion += `\\n  Secretaria: ${objsec.secretaria}`;
      }
    } else if(objrep.nombre) {
      descripcionPeticion += `\\n  Nombre del Representante TIC: ${objrep.nombre}`;
      descripcionPeticion += `\\n  Email del Representante TIC: ${this.props.solicitante.email}`;
      descripcionPeticion += `\\n  Sede: ${objrep.dependencia}`;
    } else if(objauth.nombre) {
      descripcionPeticion += `\\n  Nombre de la Autoridad: ${objauth.nombre}`;
      descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`;
      descripcionPeticion += `\\n  Sede: ${objauth.dependencia}`;
    }
    descripcionPeticion += `\\n  --------------- Datos del TRAMITE -----------------`;
    descripcionPeticion += `\\n  Tipo de Licencia: ${formData.tipoLicencia}`;
    
    if(formData.tipoFacultad)
      descripcionPeticion += ` \\n  Sede: ${formData.tipoFacultad || '-'}`;
    if(formData.fechaWebinar)
      descripcionPeticion += ` \\n  Fecha: ${formData.fechaWebinar || '-'}`;
    if(formData.correo)
      descripcionPeticion += ` \\n  Usuario Zoom PRO al que se le asigna el Webinar: ${formData.correo || '-'}`;
    
    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;

    if(formData.detalle)
      descripcionPeticion += ` \\n  Detalle de cuentas: ${formData.detalle || '-'}`;

    const tramiteTipo = 'LICENCIA_SOFTWARE';



    var tramiteDefinition;
    
    tramiteDefinition = `
      {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 10936,
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": ${ assigned},
        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
        ${ watchers || ''}
      }
      }`;

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    if(objsecparent.email)
      objetoenviar.emailparent = objsecparent.email;

    this.props.actions.saveTramite(
      objetoenviar,
      () => { this.props.actions.nextStep(); }
    );

  }

  changeTipoLicencia = value => {
    let showSaveButton = true;
    let texto = <span />;
    if(value == 'antivirus') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor antes de realizar la solitud ingrese a la documentación de la licencia Antivirus haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/licencias-de-software/tic-licencias/antivirus">click aqui</a>  </p>; 
    }

    if(value == 'autodesk') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor ingrese a la documentación de la licencia Autodesk para realizar la solicitud haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/licencias-de-software/tic-licencias/autodesk">click aqui</a>  </p>;
      showSaveButton = false;
    }
    if(value == 'labview') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor ingrese a la documentación de la licencia LabView para realizar la solicitud haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/licencias-de-software/tic-licencias/labview-licencias">click aqui</a>  </p>;
      showSaveButton = false;
    }
    if(value == 'mathlab') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor ingrese a la documentación de la licencia MathLab para realizar la solicitud haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/licencias-de-software/tic-licencias/mathlab-simulink">click aqui</a>  </p>;
      showSaveButton = false;
    }
    if(value == 'microsoft') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor antes de realizar la solitud ingrese a la documentación de la licencia Microsoft haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/licencias-de-software/tic-licencias/microsoft">click aqui</a>  </p>;
    }
    if(value == 'zoom') {
      texto = <p style={ { marginLeft:150,marginTop:15,marginBottom:15,fontSize: '115%',fontWeight:'bold'  } }>Por favor antes de realizar la solitud ingrese a la documentación de la licencia Zoom haciendo <a target="_blank" href="https://utn.edu.ar/es/secretaria-tic/servicios/tic-servicios/zoom">click aqui</a>  </p>;     
    }

    this.setState({
      renderLicencias: texto,
      showSaveButton: showSaveButton,
      accion: value
      
    })

  }

  render() {
    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form
            onSave={ this.handleSave }
            isFormEdition={ false }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
            showSaveButton= { this.state.showSaveButton }
          >
            <SelectItem
              label="Tipo de licencia"
              name="tipoLicencia"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label'] }
              onChange={ this.changeTipoLicencia }
              staticData={ [
                { label: 'Antivirus', value: 'antivirus' },
                { label: 'Autodesk', value: 'autodesk' },
                { label: 'LabView', value: 'labview' },
                { label: 'MathLab', value: 'mathlab' },
                { label: 'Microsoft', value: 'microsoft' },
                { label: 'Zoom', value: 'zoom' },
                { label: 'Zoom Webinar', value: 'webinar' }

              ] }
              rules={ [{ required: true, message: 'Debe especificar que licencia esta solicitando.' }] }
            />
            { this.state.renderLicencias }

            { this.state.showSaveButton && this.state.accion == 'webinar' ?
              [
                <SelectItem
                  label="Sede"
                  name="tipoFacultad"
                  fieldsValues={ ['value'] }
                  fieldsToShow={ ['label'] }
                  staticData={ secretariasJson }
                  rules={ [{ required: true, message: 'La Sede es requerida' }] }
                />,
                <DateItem
                  key = { "fechaWebinar"  }
                  label="Fecha de Zoom Webinar"
                  name={ "fechaWebinar" }
                  rules={ [{ required: true, message: 'La fecha  es requerida' }] }
                />,
                <InputItem
                  label="Usuario Zoom PRO al que se le asigna el Webinar"
                  name="correo"
                  rules={ 
                    [{
                      required: true,
                      message: 'El email institucional es obligatorio'
                    },
                    {
                      type: 'email',
                      message: 'Debe ingresar un email valido',
                    },
                    {
                      type: 'CHECK_UTN_DOMAIN'
                    }
                    ] 
                  }
                />
              ] : <span/>
            }
            
            { /* 
            this.state.showSaveButton ?   
              <SelectItem
                label="Sede"
                name="tipofacultad"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ secretariasJson }
                rules={ [{ required: true, message: 'Debe seleccionar una Facultad Regional para continuar.' }] }
            /> : <span />  */} 

            { this.state.showSaveButton && this.state.accion != 'zoom' ? (<TextAreaItem
              label="Comentario"
              name="comentario"
            />) : <span />
            }

            { this.state.showSaveButton && this.state.accion == 'zoom'  ? (<TextAreaItem
              label="Detalle de cuentas"
              name="detalle"
              placeholder = "Listar las cuentas con licencias básicas que solicita pasar a pro"
              help = "Listar las cuentas con licencias básicas que solicita pasar a pro"
            />) : <span />
            }
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de Solicitud de Licencias de Software` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}

TramiteSolicitudLicenciasSoftwareStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteSolicitudLicenciasSoftwareStep;
