import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Icon } from 'antd';
import screenfull from 'screenfull';
import Routes from './Routes/Routes';
import './appContent.less';
import HeaderUtn from '../../../tramites/components/headerUtn';
import FooterUtn from '../../..//tramites/components/footerUtn';


const { Header, Footer } = Layout;

const AppContent = ({ user, collapsedMainSideBar, toggleMainSideBar, toggleRightSider }) => {
  
  const fullScreen = () => {
    if(screenfull.isFullscreen){
      screenfull.exit();
    }else{
      screenfull.request();
    }
  };
  
  
  return(
    <Layout>
      {
        user.logged &&
        <Header>
          <Icon
            className="trigger header__position"
            type={ collapsedMainSideBar ? 'menu-unfold' : 'menu-fold' }
            onClick={ toggleMainSideBar }
          />
          <div className="header__badge-box">
            <div className="header__badge" onClick={ toggleRightSider }>
              <Icon
                className="trigger size header__position"
                type="setting"
              />
            </div>
            <div className="header__badge" onClick={ fullScreen }>
              <Icon
                className="trigger size header__position"
                type="arrows-alt"
              />
            </div>
          </div>
        </Header>
      }

      <HeaderUtn />

      <Routes />

      <FooterUtn />
      {
        user.logged &&
        <Footer
          style={ { textAlign: 'center', backgroundColor: '#fff' } }
        >
          B12 Starter
        </Footer>
      }
    </Layout>
  );
};

AppContent.propTypes = {
  user: PropTypes.object.isRequired,
  collapsedMainSideBar: PropTypes.bool.isRequired,
  toggleMainSideBar: PropTypes.func.isRequired,
  toggleRightSider: PropTypes.func.isRequired
};

export default AppContent;
