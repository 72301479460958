import * as actionsTypes from './authConstants';
import AuthenticationHelper from '../../shared/helpers/authenticationHelper';
import AuthorizationHelper from '../../shared/helpers/authorizationHelper';
import ConfigurationHelper from '../../shared/helpers/ConfigurationHelper';

const initialState = {
  user: {
    nombre: '',
  },
  isRecoverSuccess: false,
  gettingUserPermissions: false,
  userPermissions: {},
  updatingUserPermissions: false,
  logingIn: false,
  searchingUsers: false,
  savingUser: false,
  users:[],
  errorMessage: '',
  confirmingUser: false,
  gettingUserConfirmationInfo: false,
  userConfirmationInfo:{
    nombre:'',
    tipo:'',
    razon_social:'',
    email:''
  }
};
const usableState = Object.assign({}, initialState);

if (AuthenticationHelper.isJwtTokenStored()) {
  usableState.user = AuthenticationHelper.getDecodedToken();
  usableState.user.logged = true;
}

export default (state = usableState, action) => {
  let editedState;
  switch (action.type) {
    case actionsTypes.PERFORMING_LOGIN:
      editedState = Object.assign({}, state);
      editedState.logingIn = true;
      editedState.user.logged = false;
      break;

    case actionsTypes.LOGIN_SUCCESS:
      editedState = Object.assign({}, state);
      AuthenticationHelper.storeJwtToken(action.data.loginReturnObject.token);
      AuthorizationHelper.storeUserPermissions(JSON.parse(action.data.loginReturnObject.permisos[0].permisos));
      ConfigurationHelper.storeParameters(action.data.loginReturnObject.configuracion);
      editedState.user = AuthenticationHelper.getDecodedToken();
      editedState.logingIn = false;
      editedState.user.logged = true;
      editedState.errorMessage = '';
      break;

    case actionsTypes.LOGIN_FAILURE:
      editedState = Object.assign({}, state);
      editedState.user.logged = false;
      editedState.logingIn = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.PERFORMING_LOGOUT:
      editedState = Object.assign({}, state);
      editedState.logingIn = true;
      break;

    case actionsTypes.LOGOUT_SUCCESS:
      AuthenticationHelper.removeJwtToken();
      ConfigurationHelper.removeParameters();
      editedState = Object.assign({}, initialState);
      break;

    case actionsTypes.LOGOUT_FAILURE:
      editedState = Object.assign({}, state);
      editedState.logingIn = false;
      break;
    
    case actionsTypes.PERFORMING_RECOVER:
      editedState = Object.assign({}, state);
      editedState.logingIn = true;
      editedState.errorMessage = '';
      break;

    case actionsTypes.RECOVER_SUCCESS:
      AuthenticationHelper.removeJwtToken();
      ConfigurationHelper.removeParameters();
      editedState = Object.assign({}, initialState);
      editedState.isRecoverSuccess = true;
      break;

    case actionsTypes.RECOVER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.logingIn = false;
      break;

    case actionsTypes.GETTING_USER_PERMISSIONS:
      editedState = Object.assign({}, state);
      editedState.gettingUserPermissions = true;
      break;

    case actionsTypes.GET_USER_PERMISSIONS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.gettingUserPermissions = false;
      editedState.userPermissions = action.data.computedUserPermissions;
      break;

    case actionsTypes.GET_USER_PERMISSIONS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.gettingUserPermissions = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.UPDATING_USER_PERMISSIONS:
      editedState = Object.assign({}, state);
      editedState.updatingUserPermissions = true;
      break;

    case actionsTypes.UPDATE_USER_PERMISSIONS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.updatingUserPermissions = false;
      break;

    case actionsTypes.UPDATE_USER_PERMISSIONS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.updatingUserPermissions = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.SEARCHING_USERS:
      editedState = Object.assign({}, state);
      editedState.searchingUsers = true;
      break;

    case actionsTypes.SEARCH_USERS_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.users = action.data;
      editedState.searchingUsers = false;
      break;

    case actionsTypes.SEARCH_USERS_FAILURE:
      editedState = Object.assign({}, state);
      editedState.searchingUsers = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.SAVING_USER:
      editedState = Object.assign({}, state);
      editedState.savingUser = true;
      break;

    case actionsTypes.SAVE_USER_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.user = action.data;
      editedState.savingUser = false;
      break;

    case actionsTypes.SAVE_USER_FAILURE:
      editedState = Object.assign({}, state);
      editedState.savingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.GETTING_USER_CONFIRMATION_INFO:
      editedState = Object.assign({}, state);
      editedState.gettingUserConfirmationInfo = true;
      break;

    case actionsTypes.GET_USER_CONFIRMATION_INFO_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.userConfirmationInfo = action.data;
      editedState.gettingUserConfirmationInfo = false;
      break;
      
    case actionsTypes.GET_USER_CONFIRMATION_INFO_FAILURE:
      editedState = Object.assign({}, state);
      editedState.gettingUserConfirmationInfo = false;
      editedState.errorMessage = action.errorMessage;
      break;

    case actionsTypes.CONFIRMING_USER:
      editedState = Object.assign({}, state);
      editedState.confirmingUser = true;
      break;

    case actionsTypes.USER_CONFIRMED_SUCCESS:
      editedState = Object.assign({}, state);
      editedState.confirmingUser = false;
      break;
      
    case actionsTypes.USER_CONFIRMED_FAILURE:
      editedState = Object.assign({}, state);
      editedState.confirmingUser = false;
      editedState.errorMessage = action.errorMessage;
      break;

    default:
      return state;
  }
  return editedState;
};
