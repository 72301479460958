import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import Form from '../../../shared/components/genericForm/Form';
import InputItem from '../../../shared/components/genericForm/formItems/InputItem';
import SelectItem from '../../../shared/components/genericForm/formItems/SelectItem';

let emailRules = [
  {
    required: true,
    message: 'El email es obligatorio'
  },
 
  {
    type: 'CHECK_UTN_DOMAIN',
    message: 'El mail debe pertenecer al dominio utn.edu.ar'
  }
   
];




let tramitesValidanEmailSecretario = [2,4,5,8,10];

class DatosSolicitante extends React.Component {
  

  constructor(props) {
    
    super(props);
    
   
 
  
  }

  componentDidMount() {
   


  }
 
  

  handleSave = formData => {
    
    this.props.actions.setSolicitante({
      nombre: formData.nombreSolicitante,
      email: formData.correoSolicitante.trim(),
      secretaria: formData.tiposecretaria,
      interno: formData.internoSolicitante
    });
    
    this.props.actions.nextStep();
  }

  render() {  
    let nombre = "Nombre del Solicitante";
    let email = "Correo institucional del solicitante";
    if(this.props.match.params.numeroTramite == 2 || this.props.match.params.numeroTramite == 5 || this.props.match.params.numeroTramite == 8 || this.props.match.params.numeroTramite == 10)
    {
      nombre = null;
      email = "Correo institucional de la Autoridad";
    } else if ( this.props.match.params.numeroTramite == 6) {
      nombre = null;
      email = "Correo del Representante TIC";
    } else if ( this.props.match.params.numeroTramite == 9) {
      nombre = null;
      email = "Correo del solicitante";
    }


    let emailRules_alt = [ ...emailRules ];
    let validateSecretarioEmail = tramitesValidanEmailSecretario.find(tramiteId => this.props.tramiteSeleccionado.tramiteId === tramiteId);
    if(this.props.tramiteSeleccionado.tramiteId == 9)
    {
      emailRules_alt.splice(-1,1);
    }
   
    if(this.props.tramiteSeleccionado.tramiteId == 1)
    {
      emailRules_alt.push({
        type: 'CHECK_RECTORADO_EMAIL',
        message: 'El email debe pertenecer a Rectorado @utn.edu.ar o @rec.utn.edu.ar'
      });

    }
    if(this.props.tramiteSeleccionado.tramiteId == 6)
    {
      emailRules_alt.push({
        type: 'CHECK_REPRESENTANTE_EMAIL',
        message: 'El email debe pertenecer a un Representante TIC o una Autoridad de Rectorado'
      });

    }
   
    /*
    if(this.props.tramiteSeleccionado.tramiteId == 9)
    {
      emailRules_alt.push({
        type: 'CHECK_SIED_EMAIL',
        message: 'El email debe pertenecer a un Autorizado SIED (Sec. Académica)'
      });

    }
    */

    if (validateSecretarioEmail){
      
      emailRules_alt.push({
        type: 'CHECK_SECRETARIO_EMAIL',
        message: 'Este trámite debe ser iniciado por un Secretario de Rectorado.'
      });
    }

    if (this.props.tramiteSeleccionado.tramiteId == 3){
      
      emailRules_alt.push({
        type: 'CHECK_DECANO_EMAIL'
      });
    }

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20} }>
          <Form 
            title={ 'datos solicitante' }
            onSave={ this.handleSave }
            isFormEdition={ false }
            saveButtonText={ 'Continuar' }
          >
            { nombre ?
              <InputItem
                label={ nombre }
                name="nombreSolicitante"
              /> : <span/>
            }
            <InputItem
              label={ email }
              name="correoSolicitante"
              rules={ emailRules_alt }
            />
            { this.props.match.params.numeroTramite == 1  ?
              <SelectItem
                label="Secretaría Rectorado"
                name="tiposecretaria"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ [
                  { "value":"GENERAL", "label":"General" },
                  { "value":"ACADEMICA", "label":"Academica" },
                  { "value":"ADMINISTRATIVA", "label":"Administrativa" },
                  { "value":"ASUNTOS_ESTUDIANTILES", "label":"Asuntos Estudiantiles" },
                  { "value":"CONSEJO_SUPERIOR", "label":"Consejo Superior" },
                  { "value":"EXTENSION_UNIVERSITARIA", "label":"Extension Universitaria" },
                  { "value":"POLITICAS_INSTITUCIONALES", "label":"Politicas Institucionales" },
                  { "value":"RELACIONES_INTERNACIONALES", "label":"Relaciones Internacionales" },
                  { "value":"CIENCIA_TECNOLOGIA_POSGRADO", "label":"Ciencia Tecnología y Posgrado" },
                  { "value":"TIC", "label":"TIC" }
                ] }
                rules={ [{ required: true, message: 'Debe seleccionar una Secretaría para continuar.' }] }
              /> : <span /> }
            { this.props.match.params.numeroTramite == 1  ?
              <InputItem
                label="Interno"
                name="internoSolicitante"
              /> : <span /> }
           
          </Form>
        </Card>
        <PageHeader 
          title={ `Trámite Seleccionado: ${this.props.tramiteSeleccionado.title}` } 
          subTitle="Luego de ingresar los datos del solicitante, haga click en el boton continuar" 
        />
      </Fragment>
    );
  }
}

DatosSolicitante.propTypes = {
  trabajando: PropTypes.bool
};

DatosSolicitante.defaultProps = {
  trabajando: false
};

export default DatosSolicitante;
