import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
//import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
import sied from '../../sied';

import SedesUTNItem from '../../../../shared/components/genericForm/customFormItems/sedesUTN/SedesUTNItem';

const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteSiedStep extends React.Component {
  state = {
    tipoTramite:null,
    arrayFacultades: [],
    isAuthorized:false
  }

  

  handleChangeSolicitud = value => {
    let tipo;
    if(value == 'CREAR_CONSORCIO')
      tipo = 1;
    if(value == 'CREAR_USUARIO_PRUEBA')
      tipo = 2;
    if(value == 'SOPORTE_ACADEMICO' || value == 'SOPORTE_TECNICO' )
      tipo = 3; 
    if(value == 'CONSULTA_GENERAL' )
      tipo = 4;         
    
    this.setState({
      tipoTramite:tipo
    })
  }

  handleSave = formData => {
    let cant_dias = 7;
    let assigned;
    let proyectId = 101;
    let parent;
    let watchers = null; 
    
      
    

    if(formData.tipo == 'CREAR_CONSORCIO') {
      assigned = '452';
      parent = '12397';
      watchers = ',"watcher_user_ids":[502]';
    } else if( formData.tipo == 'CREAR_USUARIO_PRUEBA' ) {
      assigned = '452';
      parent = '12397';
      watchers = ',"watcher_user_ids":[502]';
    } else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'LIC_EN_ADMINISTRACION_DE_EMPRESAS' ) {
      proyectId = 77; 
      assigned = '503';
      parent = '12806';
      watchers = ',"watcher_user_ids":[504]';
    } else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'LIC_EN_TECNOLOGIA_EDUCATIVA' ) {
      proyectId = 93;
      assigned = '503';
      parent = '12814';
      watchers = ',"watcher_user_ids":[504]';
    } else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'TU_EN_GESTION_AERONAUTICA' ) {
      proyectId = 76;
      assigned = '503';
      parent = '12810';
      watchers = ',"watcher_user_ids":[504]';
    } else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'TU_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' ) {
      proyectId = 94;
      assigned = '503';
      parent = '12787';
      watchers = ',"watcher_user_ids":[504]';
    }	 else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'LIC_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' ) {
      proyectId = 106;
      assigned = '503';
      parent = '12790';
      watchers = ',"watcher_user_ids":[504]';
    }	 else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'TU_EN_ADMINISTRACION' ) {
      proyectId = 104;
      assigned = '503';
      parent = '12798';
      watchers = ',"watcher_user_ids":[504]';
    }	 else if( formData.tipo == 'SOPORTE_ACADEMICO' && formData.consorcio == 'TU_EN_CIUDADES_INTELIGENTES' ) {
      proyectId = 105;
      assigned = '503';
      parent = '12802';
      watchers = ',"watcher_user_ids":[504]';
    }	 else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'LIC_EN_ADMINISTRACION_DE_EMPRESAS' ) {
      proyectId = 77; 
      assigned = '452';
      parent = '12805';
      watchers = ',"watcher_user_ids":[502]';
    } else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'LIC_EN_TECNOLOGIA_EDUCATIVA' ) {
      proyectId = 93;
      assigned = '452';
      parent = '12813';
      watchers = ',"watcher_user_ids":[502]';
    } else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'TU_EN_GESTION_AERONAUTICA' ) {
      proyectId = 76;
      assigned = '452';
      parent = '12809';
      watchers = ',"watcher_user_ids":[502]';
    }	 else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'TU_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' ) {
      proyectId = 94;
      assigned = '452';
      parent = '12786';
      watchers = ',"watcher_user_ids":[502]';
    }	 else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'LIC_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' ) {
      proyectId = 106;
      assigned = '452';
      parent = '12792';
      watchers = ',"watcher_user_ids":[502]';
    }	 else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'TU_EN_ADMINISTRACION' ) {
      proyectId = 104;
      assigned = '452';
      parent = '12797';
      watchers = ',"watcher_user_ids":[502]';
    }	 else if( formData.tipo == 'SOPORTE_TECNICO' && formData.consorcio == 'TU_EN_CIUDADES_INTELIGENTES' ) {
      proyectId = 105;
      assigned = '452';
      parent = '12801';
      watchers = ',"watcher_user_ids":[502]';
    }	else if( formData.tipo == 'CONSULTA_GENERAL' ) {
      proyectId = 34;
      assigned = '452';
      parent = '12881';
      watchers = ',"watcher_user_ids":[502]';
    }


      
    formData = caracteres_especiales(formData);
    let tituloPeticion = '';
    let objsied= { nombre:''};
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');

    let indice = sied.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
      objsied = { nombre:sied[indice].nombre };
    

    tituloPeticion = 'Trámite SIED' + ' - ' + formData.tipo + (formData.nombre_carrera ? (' - ' + formData.nombre_carrera) : '' );
    descripcionPeticion += `\\n   -------- Datos del Responsable del Tramite --------`;

    if(objsied.nombre) {
      descripcionPeticion += `\\n  Nombre de la Autoridad: ${objsied.nombre}`; 
    }
    if(formData.tipo == "SOPORTE_TECNICO" || formData.tipo == "SOPORTE_ACADEMICO" || formData.tipo == "CONSULTA_GENERAL") {
      descripcionPeticion += `\\n  Email del solicitante: ${this.props.solicitante.email}`; 
    } else {
      descripcionPeticion += `\\n  Email de la Autoridad: ${this.props.solicitante.email}`; 
    }
			
    
    descripcionPeticion += `\\n  --------------- Datos del TRAMITE -----------------`;
    descripcionPeticion += `\\n  Tipo de Solicitud: ${formData.tipo}`;

    if(formData.tipo == "CREAR_CONSORCIO") {

      descripcionPeticion += `\\n  Nombre de la carrera : ${formData.nombre_carrera || '-'}`;
    
      descripcionPeticion += `\\n  Nombre corto de la carrera: ${formData.nombre_corto_carrera || '-' }`;
      descripcionPeticion += `\\n  Regionales Participantes: ${(formData.factultadesIntervinientes && formData.factultadesIntervinientes.join()) || '-'}`;
      descripcionPeticion += `\\n  Regional cabecera a la que pertenece el consorcio: ${formData.facultad_cabecera  || '-'}`;
      descripcionPeticion += `\\n  Nombre completo del administrador del entorno EVEA: ${formData.admin_EVEA  || '-'}`;
      descripcionPeticion += `\\n  Email Institucional del administrador del entorno EVEA: ${formData.email_EVEA  || '-'}`;
    }

    if(formData.tipo == "CREAR_USUARIO_PRUEBA") {

    
      descripcionPeticion += `\\n  Nombre del Consorcio : ${formData.nombre_consorcio || '-'}`;
    
      descripcionPeticion += `\\n  Regional Solicitante: ${formData.regional_solicitante || '-' }`;
      descripcionPeticion += `\\n  Regionales Participantes: ${(formData.factultadesIntervinientes && formData.factultadesIntervinientes.join()) || '-'}`;
      descripcionPeticion += `\\n  Nombre del usuario de prueba: ${formData.nombre_usuario_prueba  || '-'}`;
      descripcionPeticion += `\\n  Email del usuario de prueba: ${formData.email_usuario_prueba  || '-'}`;
    }

    if(formData.tipo == "SOPORTE_TECNICO" || formData.tipo == "SOPORTE_ACADEMICO") {

      descripcionPeticion += `\\n  Nombre: ${formData.nombre_solicitante || '-'}`;
      descripcionPeticion += `\\n  Consorcio: ${formData.consorcio || '-'}`;
      descripcionPeticion += `\\n  Descripción del incidente: ${formData.descripcion_incidente || '-'}`;
    
    }

    if(formData.tipo == "CONSULTA_GENERAL") {

      descripcionPeticion += `\\n  Nombre: ${formData.nombre_solicitante || '-'}`;
    
    }


    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;

    const tramiteTipo = 'SIED';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": ${ proyectId},
        "parent_issue_id": ${ parent},
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": ${ assigned},
        "priority_id": 2,
		"custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
        ${ watchers || ''}
      }
    }`;
	
    /* const tramiteDefinition = `
    {
      "issue": {
        "project_id": 101,
        "parent_issue_id": ${ parent},
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": ${ assigned},
        "priority_id": 2,
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
      }
    }`;
    */

    let objetoenviar = {tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email  }
    

    this.props.actions.saveTramite(
      objetoenviar,
      () => { this.props.actions.nextStep(); }
    );
    

  }

  componentDidMount() {
    let indice = sied.findIndex(elem => elem.email == this.props.solicitante.email);
    if(indice > -1)
    {
      
      this.setState({
        isAuthorized:true
      });
    }

  }

  seleccionFacultad = arrayFacultades => {
    let arrayNuevo = arrayFacultades.map(elem => ({ value:elem , label:elem }))

    this.setState( {
      arrayFacultades:[]
    },function(){
      this.setState( {
        arrayFacultades:arrayNuevo
      }

      )
    })

    
   
  }

  render() {

    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } }>
          <Form 
            onSave={ this.handleSave }
            isFormEdition={ false }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
            { this.state.isAuthorized ?
              <SelectItem
                onChange={ this.handleChangeSolicitud }
                label="Tipo de Trámite"
                name="tipo"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ [
                  { label: 'Crear Nuevo Consorcio', value: 'CREAR_CONSORCIO' },
                  { label: 'Crear Nuevo usuario de prueba', value: 'CREAR_USUARIO_PRUEBA' },
                  { label: 'Soporte Académico', value: 'SOPORTE_ACADEMICO' },
                  { label: 'Soporte Técnico', value: 'SOPORTE_TECNICO' },
                  { label: 'Consulta General', value: 'CONSULTA_GENERAL' },

                ] }
                rules={ [{ required: true, message: 'Este campo es requerido' }] }
              />
              : <span/>
            }

            { !this.state.isAuthorized ?
              <SelectItem
                onChange={ this.handleChangeSolicitud }
                label="Tipo de Trámite"
                name="tipo"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ [
                  { label: 'Soporte Académico', value: 'SOPORTE_ACADEMICO' },
                  { label: 'Soporte Técnico', value: 'SOPORTE_TECNICO' },
                  { label: 'Consulta General', value: 'CONSULTA_GENERAL' },
                ] }
                rules={ [{ required: true, message: 'Este campo es requerido' }] }
              />
              : <span/>
            }
              
            { this.state.tipoTramite == 1 ?
              [
                <InputItem
                  label="Nombre de la carrera"
                  name="nombre_carrera"
                  rules={ [{ required: true, message: 'El nombre es obligatorio' }] }
                  placeholder="Por ejemplo: TECNICATURA EN GESTIÓN AERONÁUTICA"

                />,

                <InputItem
                  label="Nombre corto de la carrera"
                  name="nombre_corto_carrera"
                  rules={ [{ required: true, message: 'El nombre corto es obligatorio' }] }
                  placeholder="Por ejemplo: TUGA"

                />,

              
                <SedesUTNItem
                  onChange={ this.seleccionFacultad }
                  label="Facultades Regionales Participantes"
                  name="factultadesIntervinientes"
                  mode="multiple"
                  soloFacultades={ true }
                  rules={ [{ required: true, message: 'Debe indicar las Facultades que intervendran' }] }
                /> ] : <span/>
            }

            { this.state.tipoTramite == 2 ?
              [
                <InputItem
                  label="Nombre del Consorcio"
                  name="nombre_consorcio"
                  rules={ [{ required: true, message: 'El nombre es obligatorio' }] }
                  

                />,

                <SedesUTNItem
                  label="Regional Solicitante"
                  name="regionalsolicitante"
                  soloFacultades={ true }
                  rules={ [{ required: true, message: 'Debe indicar la Facultad que interviene' }] }
                />,
                <InputItem
                  label="Nombre del usuario de prueba"
                  name="npmbre_usuario_prueba"
                  rules={ [{ required: true, message: 'El nombre es obligatorio' }] }
                
                />,

                <InputItem
                  label="Email del usuario de prueba"
                  name="email_usuario_prueba"
                  rules={ [{ required: true, message: 'El email es obligatorio' },{  type: 'CHECK_UTN_DOMAIN',message:"El correo debe pertenecer al dominio UTN" }] }
                

                />

              ] : <span/>
            }

            { this.state.tipoTramite == 3 ?
              [
                <SelectItem
                  key="item1"
                  label="Consorcio"
                  name="consorcio"
                  fieldsValues={ ['value'] }
                  fieldsToShow={ ['label'] }
                  staticData={  [
                    { label: 'Lic. en Administración de Empresas', value: 'LIC_EN_ADMINISTRACION_DE_EMPRESAS' },
                    { label: 'Lic. en Tecnología Educativa', value: 'LIC_EN_TECNOLOGIA_EDUCATIVA' },
                    { label: 'TU en Gestión Aeronáutica', value: 'TU_EN_GESTION_AERONAUTICA' },
                    { label: 'TU en Higiene y Seguridad en el Trabajo', value: 'TU_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' },
                    { label: 'Lic. en Higiene y Seguridad en el Trabajo', value: 'LIC_EN_HIGIENE_Y_SEGURIDAD_EN_EL_TRABAJO' },
                    { label: 'TU en Administración', value: 'TU_EN_ADMINISTRACION' },
                    { label: 'TU en Ciudades Inteligentes', value: 'TU_EN_CIUDADES_INTELIGENTES' },
              
                  ] }
                  rules={ [{ required: true, message: 'Este campo es requerido' }] }
                />,
                <InputItem
                  key="item3"
                  label="Nombre"
                  name="nombre_solicitante"
                  rules={ [{ required: true, message: 'El nombre es obligatorio' }] }                
                />,


              ] : <span/>
            }

            { this.state.tipoTramite == 4 ?
              [
                <InputItem
                  key="item3"
                  label="Nombre"
                  name="nombre_solicitante"
                  rules={ [{ required: true, message: 'El nombre es obligatorio' }] }                
                />,


              ] : <span/>
            }




            { this.state.arrayFacultades.length && this.state.tipoTramite == 1 ?
              <SelectItem
                label="Facultad Reional Cabecera"
                name="facultad_cabecera"
                fieldsValues={ ['value'] }
                fieldsToShow={ ['label'] }
                staticData={ this.state.arrayFacultades }
                rules={ [{ required: true, message: 'La Facultad cabecera es requerida' }] }
              />: <span/>
            } 

            { this.state.tipoTramite == 1 ?
              [
                <InputItem
                  label="Nombre completo del administrador del entorno EVEA."
                  name="admin_EVEA"
                  rules={ [{ required: true, message: 'En este campo es requerido' }] }

                />,

                <InputItem
                  label="Correo Institucional del administrador del entorno EVEA"
                  name="email_EVEA"
                  rules={ [{  type: 'CHECK_UTN_DOMAIN',message:"El correo debe pertenecer al dominio UTN" },{ required: true, message: 'En este campo es requerido' }] }

                />
              

            
              ] : <span/>

            }
            { this.state.tipoTramite === 1 || this.state.tipoTramite === 2 || this.state.tipoTramite === 4 ? 
              <TextAreaItem
                label={ "Comentario" }
                name="comentario"
              /> : <span/> 
            }

            { this.state.tipoTramite === 3 ? 
              <TextAreaItem
                label={ "Desripción del incidente" }
                name="descripcion_incidente"
                rules={ [{ required: true, message: 'En este campo es requerido' }] }
              /> : <span/> 
            } 
          </Form>
        </Card>
        <PageHeader title={ `Tramite Seleccionado: Tramite de Telefonos Internos` } subTitle="Luego de cargar los datos del tramite presione el boton 'Iniciar Tramite'." />
      </Fragment>
    );
  }
}



export default TramiteSiedStep;
