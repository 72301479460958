import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Input, Button } from 'antd';


const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      isFormSent: true,
      logingIn: false
    };
  }

  componentDidMount() {
    this.setState({logingIn: this.props.isWorking});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isWorking !== this.props.isWorking && !this.props.isWorking) {
      this.setState({logingIn: false});
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({logingIn: true, isFormSent: true});
        this.props.handleLogin({ user: this.state.user, password: this.state.password });
      }
      else {
        this.setState({isFormSent: false});
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const loginErrorMessageVisibility = this.props.loginErrorMessage
      && !this.state.isLoading
      && this.state.isFormSent ? 'block' : 'none';

    return(
      <div>
        <Form onSubmit = { event => { event.preventDefault(); this.handleSubmit(); } }>
          <FormItem style ={ { paddingTop: 10 } } >
            {getFieldDecorator('userName', {
              rules: [{ required: true, message: (this.props.userInputErrorMessage ? this.props.userInputErrorMessage : '') }]
            })(
              <Input
                prefix={ <Icon type="user" style ={ { fontSize: 13 } } /> }
                placeholder={ this.props.userInputPlaceholder ? this.props.userInputPlaceholder : '' }
                onChange={ event => this.setState({ user: event.target.value }) }
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: (this.props.passInputErrorMessage ? this.props.passInputErrorMessage : '') }]
            })(
              <Input
                prefix={ <Icon type="lock" style={ { fontSize: 13 } } /> }
                type="password"
                placeholder={ this.props.passInputPlaceholder ? this.props.passInputPlaceholder : '' }
                onChange={ event => this.setState({ password: event.target.value }) }
              />
            )}
          </FormItem>
          <FormItem>
            <Button
              type = "primary"
              htmlType = "submit"
              loading = { this.state.logingIn }
            >
              { this.props.logInButtonText }
            </Button>
          </FormItem>
          <p className="loginErrorMessage" style={ { display: loginErrorMessageVisibility, ...this.props.styles.loginErrorMessage } }>
            {this.props.loginErrorMessage}
          </p>
        </Form>
      </div>
    );
  }
}

Login.propTypes = {
  logInButtonText: PropTypes.string.isRequired,
  userInputPlaceholder: PropTypes.string.isRequired,
  userInputErrorMessage: PropTypes.string.isRequired,
  passInputPlaceholder: PropTypes.string.isRequired,
  passInputErrorMessage: PropTypes.string.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  isWorking: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    loginErrorMessage: PropTypes.object
  })
};

Login.defaultProps = {
  styles: {}
};

export default Form.create()(Login);
