export const SEARCHING_USERS = 'SEARCHING_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

export const SEARCHING_USER = 'SEARCHING_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const SAVING_USER = 'SAVING_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const ENABLING_USER = 'ENABLING_USER';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const DISABLING_USER = 'DISABLING_USER';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';

export const DELETING_USER = 'DELETING_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const CLEAN_SELECTED_USER = 'CLEAN_SELECTED_USER';
