export const PERFORMING_LOGIN = 'PERFORMING_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const PERFORMING_LOGOUT = 'PERFORMING_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const PERFORMING_RECOVER = 'RECOVERING_PASSWORD';
export const RECOVER_SUCCESS = 'RECOVER_SUCCESS';
export const RECOVER_FAILURE = 'RECOVER_FAILURE';

export const GETTING_USER_PERMISSIONS = 'GETTING_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_FAILURE = 'GET_USER_PERMISSIONS_FAILURE';

export const UPDATING_USER_PERMISSIONS = 'UPDATING_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_FAILURE = 'UPDATE_USER_PERMISSIONS_FAILURE';

export const SEARCHING_USERS = 'SEARCHING_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

export const SAVING_USER = 'SAVING_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const GETTING_USER_CONFIRMATION_INFO = 'GETTING_USER_CONFIRMATION_INFO';
export const GET_USER_CONFIRMATION_INFO_SUCCESS = 'GET_USER_CONFIRMATION_INFO_SUCCESS';
export const GET_USER_CONFIRMATION_INFO_FAILURE = 'GET_USER_CONFIRMATION_INFO_FAILURE';

export const CONFIRMING_USER = 'CONFIRMING_USER';
export const USER_CONFIRMED_SUCCESS = 'USER_CONFIRMED_SUCCESS';
export const USER_CONFIRMED_FAILURE = 'USER_CONFIRMED_FAILURE';