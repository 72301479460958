import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Table, Tooltip, Button, Popconfirm, Row, Col, Tag } from "antd";

class GenericTable extends React.Component {

  constructor(props){
    super(props);
    this.addExtraColumnsToTable();
  }

  renderShowButton = entity => {
    if (this.props.showButtonUri){
      return (
        <React.Fragment>
          <Link to={ 
            {
              pathname: `${this.props.showButtonUri}/${entity.id}`,
              param1: 'view'
            } 
          }>
            <Tooltip title="Ver"><Button shape="circle" icon="eye" size={ 'small' } /></Tooltip>
          </Link>
          { ' ' }
        </React.Fragment>
      );
    }else{
      if(this.props.debug){
        console.warn('La propiedad showButtonUri no esta especificada. No se mostrara el boton en las acciones.');
      }
    }
  }

  renderNewButton = () => {
    if (this.props.newButtonUri){
      if(this.props.newButtonRender){
        return this.props.newButtonRender;
      }
      else{
        return (
          <React.Fragment>
            <Link to={ 
              {
                pathname: `${this.props.newButtonUri}`,
                param1: 'view'
              } 
            }>
              <Tooltip title="Agregar Nuevo"><Button icon="plus">{ this.props.newButtonText }</Button></Tooltip>
            </Link>
            { ' ' }
          </React.Fragment>
        );
      }
    } else {
      if (this.props.debug) {
        console.warn('La propiedad newButtonUri no esta especificada. No se mostrara el boton.');
      }
    }
  }

  renderEditButton = entity => {
    if (this.props.editButtonUri){
      if(this.props.editButtonRender){
        return this.props.editButtonRender;
      }
      else{
        return (
          <React.Fragment>
            <Link to={ 
              {
                pathname: `${this.props.editButtonUri}/${entity.id}`,
                param1: 'view'
              } 
            }>
              <Tooltip title={ this.props.editButtonTooltipText }><Button shape="circle" icon="edit" size={ 'small' } /></Tooltip>
            </Link>
            { ' ' }
          </React.Fragment>
        );
      }
    } else {
      if (this.props.debug) {
        console.warn('La propiedad editButtonUri no esta especificada. No se mostrara el boton.');
      }
    }
  }

  renderDeleteButton = entity => {
    if (this.props.onDeleteRecord){
      return (
        <React.Fragment>
          <Tooltip title="Eliminar">
            <Popconfirm title={ this.props.deleteConfirmMessage } onConfirm={ () => this.props.onDeleteRecord(entity) }>
              <Button shape="circle" icon="delete" size={ 'small' } />
            </Popconfirm>
          </Tooltip>
          { ' ' }
        </React.Fragment>
      );
    } else {
      if (this.props.debug) {
        console.warn('La funcion onDeleteRecord no esta especificada. No se mostrara el boton delete.');
      }
    }
  }

  renderEnableDisableButton = entity => {
    if (this.props.onEnableDisableRecord) {
      let textHabilitar = 'Habilitar';
      let icono = 'plus';
      if (entity.habilitado === 1){
        textHabilitar = 'Deshabilitar';
        icono = 'minus';
      }
      return (
        <React.Fragment>
          <Tooltip title={ textHabilitar }>
            <Popconfirm title={ `Seguro que desea ${textHabilitar}?` } onConfirm={ () => this.props.onEnableDisableRecord(entity) }>
              <Button shape="circle" icon={ icono } size={ 'small' } />
            </Popconfirm>
          </Tooltip>
          {' '}
        </React.Fragment>
      );
    } else {
      if (this.props.debug) {
        console.warn('La funcion onEnableDisableRecord no esta especificada. No se mostrara el boton Habilitar/Deshabilitar.');
      }
    }
  }

  renderEnableDisableColumn = entity => {
    let color = entity.habilitado == 1? 'geekblue' : 'red';
    let text = entity.habilitado == 1? 'Habilitado' : 'Deshabilitado';
    return <Tag color={ color } key={ entity.id }>{ text }</Tag>;
  }

  addExtraColumnsToTable = () => {

    let actionsButtonsColumn = {
      title: '',
      dataIndex: 'view',
      width: '20%',
      align: 'right',
      render: (text, entity) => (
        <React.Fragment>
          { this.renderShowButton(entity) }
          { this.renderEditButton(entity) }
          { this.renderDeleteButton(entity) }
          { this.renderEnableDisableButton(entity) }
        </React.Fragment>
      )
    };

    this.tableColumns = this.props.columns.slice();

    if (this.props.onEnableDisableRecord){
      this.tableColumns.forEach( column => {
        if(column.dataIndex == 'habilitado'){
          column.render = (text, entity) => (
            <React.Fragment>
              {this.renderEnableDisableColumn(entity)}
            </React.Fragment>
          );
        }
      });
    }
    this.tableColumns.push(actionsButtonsColumn);
  }

  render() {
    return (
      <React.Fragment>
        <Row gutter={ 16 } style={ { paddingTop: 24, paddingBottom: 24 } }>
          <Col xs={ 24 } md={ 24 } lg={ 24 } style={ { display: 'flex', justifyContent: 'flex-end' } }>
            { this.renderNewButton() }
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col xs={ 24 } md={ 24 } lg={ 24 }>
            <Table
              columns={ this.tableColumns }
              rowKey={ record => record.id }
              dataSource={ this.props.entities }
              loading={ this.props.loading }
              onChange={ this.handleTableChange }
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

GenericTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  entities: PropTypes.array,
  showButtonUri: PropTypes.string,
  newButtonUri: PropTypes.string,
  newButtonText: PropTypes.string,
  editButtonUri: PropTypes.string,
  editButtonTooltipText: PropTypes.string,
  onDeleteRecord: PropTypes.func,
  onEnableDisableRecord: PropTypes.func,
  debug: PropTypes.bool,
  loading: PropTypes.bool,
  deleteConfirmMessage: PropTypes.string,
};

GenericTable.defaultProps = {
  name: '',
  newButtonText: 'Nuevo',
  editButtonTooltipText: 'Editar',
  columns: [], 
  entities: [],
  debug: false,
  loading: false,
  deleteConfirmMessage: 'Seguro que desea borrar este registro?', 
};

export default GenericTable;

