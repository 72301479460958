import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginPage from './LoginPage';
import * as loginActions from '../../../../redux/auth/authActions';


function mapStateToProps(state) {
  return {
    user: state.login.user,
    errorMessage: state.login.errorMessage,
    logingIn: state.login.logingIn,
    isRecoverSuccess: state.login.isRecoverSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      login: loginActions.login,
      recoverPassword: loginActions.recoverPassword
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
