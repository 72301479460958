import sied from '../../../../pages/tramites/sied.json';


const assignIfIsSiedRule = (params, message = 'El email debe pertenecer a un responsable del pedido (Sec académica).') => {
  const checkIfIsSied = (rule, value, callback) => {
    sied.map(representante => {
      if(representante.email === value){
        callback();    
      }
    });
    
    callback(message);
  };
  return checkIfIsSied;
};

export default assignIfIsSiedRule;