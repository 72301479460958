import React, { Fragment } from 'react';
import { Card, PageHeader, Result, Button } from 'antd';
import PropTypes from 'prop-types';

class ConfirmacionTramiteStep extends React.Component {
   
  nuevoTramiteRedirect = () => {
    this.props.history.replace("/");
  }

  componentDidMount(){
    console.log(this.props)
  }

  render() {
    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20, height: 570 } }>
           
          <Result
            status="success"
            title={ "Su trámite fue registrado con el código: " + this.props.codigoTramiteGenerado }
            subTitle={ <div> { [8,2].indexOf(this.props.tramiteSeleccionado.tramiteId) > -1 ? <h5 >Para continuar  por favor verifique los datos en su casilla de correo y <strong>confirme el trámite respondiendo a la dirección indicada dentro del mismo adjuntando el DNI del usuario final.</strong></h5>:
              <h5>El personal del area correspondiente se pondrá en contacto con usted a la brevedad.</h5> }</div> }
            extra={ [
              <Button type="primary" key="console" onClick={ this.nuevoTramiteRedirect }>
                Iniciar otro trámite
              </Button>,
            ] }
          />
        </Card>
        <PageHeader title={ `Por favor guarde el código: ${this.props.codigoTramiteGenerado} para futuras consultas` }/>
      </Fragment>
    );
  }
}

ConfirmacionTramiteStep.propTypes = {
  trabajando: PropTypes.bool
};

export default ConfirmacionTramiteStep;
