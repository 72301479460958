import HomePage from '../../../../home/HomePage';
import UsuariosPage from '../../../../sistema/usuarios/UsuariosPage';
import ParametrosPage from '../../../../sistema/parametros/ParametrosPage';
import Login from '../../../../auth/authentication/login/LoginConnector';
import AuthorizationHelper from '../../../../../shared/helpers/authorizationHelper';
import TramitesPage from '../../../../tramites/TramitesPageConnector';
import BuscarTramite from '../../../../tramites/BuscarTramite';
import DependenciasPage from '../../../../dependencias/DependenciasPage';

const routes = [
  {
    key: "login",
    breadcrumbName: "Login",
    path: "/login",
    exact: true,
    component: Login
  },
  {
    permission: "dashboard.visualizar_menu_dashboard",
    key: "home",
    breadcrumbName: "Home",
    path: "/",
    exact:true,
    menu: true,
    icon: {
      type: "code",
      title: "Monitoreo"
    },
    component: HomePage
  },
  {
    key: "tramites",
    breadcrumbName: "Pagina de Tramites",
    path: "/tramites/:numeroTramite",
    menu: true,
    icon: {
      type: "solution",
      title: "Tramites"
    },
    component: TramitesPage
  },
  {
    key: "buscartramite",
    breadcrumbName: "Buscar Trámite",
    path: "/buscartramite/:issue",
    menu: true,
    icon: {
      type: "solution",
      title: "Tramites"
    },
    component: BuscarTramite
  },
  
  {
    key: "dependencias",
    breadcrumbName: "Dependencias",
    path: "/dependencias",
    menu: true,
    icon: {
      type: "solution",
      title: "Dependencias"
    },
    component: AuthorizationHelper.requireAuth(DependenciasPage)
  },
  {
    key: "sistema",
    breadcrumbName: "Sistema",
    path: "/",
    menu: true,
    icon: {
      type: "setting",
      title: "Sistema"
    },
    withItemGroup: [
      {
        key: "sistema.configuracion",
        menues: [
          {
            permission: "configuracion.visualizar_menu_parametros",
            key: "parametros-parametros",
            path: "/sistema/parametros",
            breadcrumbName: "Parametros",
            component: AuthorizationHelper.requireAuth(ParametrosPage),
            icon: {
              type: "bars",
              title: "Configuracion"
            }
          },
          {
            permission: "usuarios.visualizar_menu_usuarios",
            key: "parametros-usuarios",
            path: "/sistema/usuarios",
            breadcrumbName: "Sistema/Usuarios",
            component: AuthorizationHelper.requireAuth(UsuariosPage),
            icon: {
              type: "user",
              title: "Usuarios"
            }
          }
        ]
      }
    ]
  }
];

export default routes;
