import React, { Fragment } from 'react';
import { Card, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../../../shared/components/genericForm/Form';
import SelectItem from '../../../../shared/components/genericForm/formItems/SelectItem';
import DateItem from '../../../../shared/components/genericForm/formItems/DateItem';
import SedesUTNItem from '../../../../shared/components/genericForm/customFormItems/sedesUTN/SedesUTNItem';
import TimeItem from '../../../../shared/components/genericForm/formItems/TimeItem';
import TextAreaItem from '../../../../shared/components/genericForm/formItems/TextAreaItem';
import InputItem from '../../../../shared/components/genericForm/formItems/InputItem';


const caracteres_especiales = function (formData) {
  let formCopy = {...formData};
  for(let key in formData) {
    
    if(formData[key] && typeof formData[key] == "string" )
    {   
      formCopy[key] = formData[key].replace(/[\\]+/g, "\\\\").replace(/["]+/g, "\\\"").replace(/\r?\n/g, "\\n");
    } 
    else
    {
      formCopy[key] = formData[key]
    }

  }    

  return formCopy;

};

class TramiteFirmaDigitalStep extends React.Component {
  state = {
    tramiteSeleccionado: "NINGUNO",
    accion:''
  };

  handleSave = formData => {
    let cant_dias = 2;

    if(moment().day()== 0)
      cant_dias = 3;
    if(moment().day() > 3)
      cant_dias = 4;

    formData = caracteres_especiales(formData);
    let tituloPeticion = '';
    let descripcionPeticion = '';
    let today = moment(new Date()).utc().format();
    let dueDate = moment(today).add(cant_dias, 'd');


    tituloPeticion = 'Solicitud de Firma Digital - ' + formData.tipoTramite;
    descripcionPeticion += `\\n   -------- Datos del Responsable del Tramite --------`;
    descripcionPeticion += `\\n  Nombre: ${this.props.solicitante.nombre}`;
    descripcionPeticion += `\\n  Email: ${this.props.solicitante.email}`;
    descripcionPeticion += `\\n  --------------- Datos del TRAMITE -----------------`;
    descripcionPeticion += `\\n  Tipo de Trámite: ${formData.tipoTramite}`;
    descripcionPeticion += `\\n  Facultad Regional a la que pertenece: ${formData.facultadRegional}`;
    descripcionPeticion += `\\n  Personal solicitante: ${formData.personal}`;
    descripcionPeticion += `\\n  Email del Personal: ${formData.correo_personal}`;

    if(formData.fechaReserva)
      descripcionPeticion += `\\n  Fecha de reserva: ${formData.fechaReserva.format('DD-MM-YYYY') || '-'}`;
    
    if(formData.horaReserva)  
      descripcionPeticion += `\\n  Hora de la reserva: ${formData.horaReserva.format('HH:MM') || '-'}`;

    if(formData.fechaVencimiento)  
      descripcionPeticion += `\\n  Fecha de vecimiento de certificado: ${formData.fechaVencimiento.format('DD-MM-YYYY') || '-'}`;  

    
    if(formData.comentario)
      descripcionPeticion += ` \\n  Comentario: ${formData.comentario || '-'}`;
    


    const tramiteTipo = 'FIRMA_DIGITAL';
    const tramiteDefinition = `
    {
      "issue": {
        "project_id": 25,
        "parent_issue_id": 9589,
        "subject": "${ tituloPeticion}",
        "description": "${ descripcionPeticion}",
        "due_date": "${ dueDate.format('YYYY-MM-DD')}",
        "assigned_to_id": 336,
        "priority_id": 2,
        "watcher_user_ids":[478,328,326,328],
        "custom_fields":
        [
        {"value":"${ this.props.solicitante.email }","id":45}
        ]
      }
    }`;
    
    this.props.actions.saveTramite(
      { tipo: tramiteTipo, definicion: tramiteDefinition, emailSolicitante: this.props.solicitante.email },
      () => { this.props.actions.nextStep(); }
    );
    
    

  }


  changeTipoTramite= value => {
   
    this.setState({
      accion: value
    });

  }

  render() {
    return (
      <Fragment>
        <Card style={ { marginTop: 20, marginBottom: 20 } } >
         
          <Form 
            onSave={ this.handleSave }
            saveButtonText={ 'Iniciar Tramite' }
            saving={ this.props.savingTramite }
          >
            <SelectItem
              label="Tipo de Trámite"
              name="tipoTramite"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label'] }
              onChange={ this.changeTipoTramite }
              staticData={ [
                { label: 'Registro de Firma', value: 'REGISTRO_DE_FIRMA' },
                { label: 'Renovación de Certificados', value: 'RENOVACION_DE_CERTIFICADOS' },
                { label: 'Consulta', value: 'CONSULTA' }
              ] }
              rules={ [{ required: true, message: 'El tipo de incidente es requerido' }] }
            />

            <SedesUTNItem
              label="Facultad Regional perteneciente"
              name="facultadRegional"
              rules={ [{ required: true, message: 'Debe indicar a que Facultad pertenece' }] }
            />
            <SelectItem
              label="Personal"
              name="personal"
              fieldsValues={ ['value'] }
              fieldsToShow={ ['label'] }
              staticData={ [
                { label: 'Decano', value: 'DECANO' },
                { label: 'Secretario', value: 'SECRETARIO' },
                { label: 'Director', value: 'DIRECTOR' },
                { label: 'Otros', value: 'OTROS' },
              ] }
              rules={ [{ required: true, message: 'Este campo es requerido' }] }
            />

            <InputItem
              label="Email del Personal"
              name="correo_personal"
              rules={ 
                [{
                  required: true,
                  message: 'El campo es obligatorio'
                },
                {
                  type: 'email',
                  message: 'Debe ingresar un email valido',
                },
                ] 
              }
            />


            { this.state.accion == 'REGISTRO_DE_FIRMA' ?
              <DateItem
                label="Fecha de Reunión"
                name="fechaReserva"
                rules={ [{ required: true, message: 'La fecha de reserva es requerida' },{ type: 'CHECK_MAVI' }] }
              /> : <span/>
            }

            { this.state.accion == 'RENOVACION_DE_CERTIFICADOS' ?
              <DateItem
                label="Fecha de vencimiento de certificado"
                name="fechaVencimiento"
                rules={ [{ required: true, message: 'La fecha de vencimiento es requerida' }] }
              /> : <span/>
            }

            { this.state.accion == 'REGISTRO_DE_FIRMA' ?
              <TimeItem
                label="Hora"
                name="horaReserva"
                minuteStep={ 10 }
                rules={ [{ required: true, message: 'La hora de reserva es requerida' },{ type: 'CHECK_HORARIO_FIRMA' }] }
              /> : <span/>
            }

            { this.state.accion == 'REGISTRO_DE_FIRMA' || this.state.accion == 'RENOVACION_DE_CERTIFICADOS' ?
              <TextAreaItem
                label="Comentario adicional"
                name="comentario"
              /> : <span/>
            }


            { this.state.accion == 'CONSULTA' ?
              <TextAreaItem
                label="Consulta"
                name="consulta"
                rules={ [{ required: true, message: 'El campo es requerido' }] }
              /> : <span/>
            }
          
          </Form>
        </Card>
        <PageHeader title={ `Trámite Seleccionado: Trámite de Firma Digital` } subTitle="Luego de cargar los datos del trámite presione el boton 'Iniciar Trámite'." />
      </Fragment>
    );
  }
}

TramiteFirmaDigitalStep.propTypes = {
  trabajando: PropTypes.bool
};

export default TramiteFirmaDigitalStep;
