import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import FormularioComponent from './Formulario';
import ListadoComponent from './Listado';

import * as appActions from '../../../redux/app/appActions';
import * as parametrosActions from '../../../redux/sistema/parametros/parametrosActions';

function mapStateToProps(state) {
  return {
    parametroSeleccionado: state.sistemaParametros.parametroSeleccionado,
    parametros: state.sistemaParametros.parametros,
    trabajando: state.sistemaParametros.trabajando,
    fieldExist: state.app.fieldExist,
    validatingFieldExist: state.app.validatingFieldExist,
    fieldExistMessage: state.app.errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, appActions, parametrosActions), dispatch)
  };
}

export const Listado =  withRouter(connect(mapStateToProps, mapDispatchToProps)(ListadoComponent));
export const Formulario =  withRouter(connect(mapStateToProps, mapDispatchToProps)(FormularioComponent));


