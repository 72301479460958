import React from 'react';
import PropTypes from 'prop-types';
import { Input } from "antd";
const Search = Input.Search;
const axios = require("axios");
const voca = require('voca');

class GenericSearch extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.initialSearch)
      this.handleSearch('');
  }

  handleSearch = valueToSearch => {
    const { typeOfSearch, orderByColumn, onSearch } = this.props;

    const columnsToSearch = this.props.columnsWhereSearch.map(function (columnValue) {
      return `${columnValue}::${typeOfSearch}`;
    });

    let columnsToReturn;
    if (this.props.columnsToBeReturned.length > 0){
      if (typeof this.props.columnsToBeReturned[0] === 'string'){
        columnsToReturn = this.props.columnsToBeReturned;
      }
      if (typeof this.props.columnsToBeReturned[0] === "object") {
        columnsToReturn = this.props.columnsToBeReturned.map(a => a.dataIndex);
      }
    }
     
    axios
      .get("/application/generic-search", {
        params: {
          module: this.props.backendModule,
          model: this.props.backendEntity,
          textToSearch: `%${valueToSearch}%`,
          columnsToBeReturned: columnsToReturn.join(),
          columnsWhereSearch: columnsToSearch.join(),
          columnsToBeOrderBy: orderByColumn
        }
      })
      .then(function(response) {
        onSearch(response.data.data);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Search
          placeholder={ `Buscando en: ${voca.titleCase(this.props.columnsWhereSearch.join(', ').replace(/_/gi, " "))}` }
          onSearch={ value => this.handleSearch(value) }
          enterButton
        />
      </React.Fragment>
    );
  }
}

GenericSearch.propTypes = {
  searchResult: PropTypes.array,
  columnsToBeReturned: PropTypes.array,
  columnsWhereSearch: PropTypes.array,
  orderByColumn: PropTypes.string,
  onSearch: PropTypes.func,
  backendModule: PropTypes.string.isRequired,
  backendEntity: PropTypes.string.isRequired,
  typeOfSearch: PropTypes.string,
  initialSearch: PropTypes.bool,
  debug: PropTypes.bool
};

GenericSearch.defaultProps = {
  searchResult: [],
  columnsToBeReturned: [],
  columnsWhereSearch: [],
  orderByColumn: 'id',
  onSearch: () => {
    console.warn("[GenericSearch] onSearch not implemented.");
  },
  typeOfSearch: "like",
  initialSearch: false,
  debug: false
};

export default GenericSearch;

