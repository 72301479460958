import BackendUtilities from '../../../helpers/backendUtilities';

const assignIfExistRule = (params, message = 'Este valor ya existe.') => {
  const ifExistRule = (rule, value, callback) => {
    BackendUtilities.checkIfExist(
      {
        module: params.module,
        model: params.model,
        column: params.column,
        value: value
      },
      response => {
        if (response) {
          callback(message);
        } else {
          callback();
        }
      }
    );
  };
  return ifExistRule;
};

export default assignIfExistRule;