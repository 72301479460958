import React from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';

import AppContent from './components/AppContent/AppContentConnector';


class App extends React.Component {
  
  state = {
    collapsedMainSideBar: false,
    collapsedRightSiderBar: false
  };

  handleToggleMainSideBar = () => this.setState(oldState => ({ collapsedMainSideBar: !oldState.collapsedMainSideBar }));

  handleToggleRightSider = () => this.setState(oldState => ({ collapsedRightSiderBar: !oldState.collapsedRightSiderBar }));

  render() {
    return(
      <Layout style={ { height: '100%' } }>
      
        { /* <SideBar
          collapsed={ this.state.collapsedMainSideBar }
       /> */ }
        <AppContent
          toggleMainSideBar={ () => this.handleToggleMainSideBar() }
          toggleRightSider={ () => this.handleToggleRightSider() }
          collapsedMainSideBar={ this.state.collapsedMainSideBar }
        />
        { /*
        <RightSideBar
          collapsed={ this.state.collapsedRightSiderBar }
          handleOnClose={ () => this.handleToggleRightSider() }
          width={ 300 }
        >
          <Card title="Card Example" style={ { width: 300 } }>
            <p>Ejemplo1</p>
            <p>Ejemplo2</p>
            <p>Ejemplo3</p>
          </Card>
        </RightSideBar>
         */ }
      </Layout>
    );
  }
}

export default withRouter(App);
