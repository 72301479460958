import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, Icon } from 'antd';
import routes from './helper';

const BreadcrumbRoutes = ({ location }) => {
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const subMenu = (breadcrumbRoute, key, route) => {
    if (route.key && route.key.toUpperCase() === key.toUpperCase()) {
      breadcrumbRoute.push(
        {
          key: route.key,
          icon: route.icon,
          breadcrumbName: route.breadcrumbName,
          path: route.path ? route.path : null
        }
      );
    } else if (route.withItemGroup) {
      route.withItemGroup.forEach(menu => subMenu(breadcrumbRoute, key, menu));
    } else if (route.menues) {
      route.menues.forEach(menu =>
      {
        if (menu.key.toUpperCase() === key.toUpperCase())
          breadcrumbRoute.push(
            {
              key: menu.key,
              icon: menu.icon,
              breadcrumbName: menu.breadcrumbName,
              path: menu.path ? menu.path : null
            }
          );
      });
    }
  };

  const getNode = (breadcrumbRoute, key) => {
    routes.forEach(route => 
    {
      subMenu(breadcrumbRoute, key, route);
    });
  };
  
  let breadcrumbItems = [];
  
  pathSnippets.forEach(key => {
    getNode(breadcrumbItems, key);
  });

  return(
    <Breadcrumb>
      {
        breadcrumbItems.map(route => (
          <Breadcrumb.Item key={ route.key }>
            {
              route.path ?
                <Link to={ route.path }>
                  <Icon type={ route.icon.type } />
                  <span> { route.breadcrumbName }</span>
                </Link> :
                <span>
                  { route.icon ? <Icon type={ route.icon.type } /> : '' }
                  <span> { route.breadcrumbName }</span>
                </span>
            }
          </Breadcrumb.Item>)
        )
      }
    </Breadcrumb>
  );
};

BreadcrumbRoutes.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(BreadcrumbRoutes);
